<template>
  <allnewsApp></allnewsApp>
</template>

<script>
import allnewsApp from '@/components/media/allnewsApp.vue';
export default {
  components: { allnewsApp },
  name: "News",
    mounted(){
         document.body.classList.add('allnewsApp', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('allnewsApp', 'otherPage');
    }
};
</script>