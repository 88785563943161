<template>
  <allprod></allprod>
</template>

<script>
import allprod from '@/components/products/allproduct.vue';
export default {
  components: { allprod },
  name: "Home",
    mounted(){
         document.body.classList.add('allproduct', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('allproduct', 'otherPage');
    }
};
</script>