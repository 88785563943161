<template>
  <div class="all_news_wrap" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
        <div class="title_page">
      <h1>{{ translate_str("News") }}</h1>
    </div>
    <!-- main News -->
    <template v-for="main_news in slice_news.slice(0,1)">
         <div class="row rowup" :key="main_news.id">
        <div class="col-lg-6">
            <div class="img_news">
                <img :src="main_news.cover" :alt="main_news.title" />
            </div>
        </div>
        <div class="col-lg-6">
            <div class="text_news">
                <span class="date_news">{{main_news.date}}</span>
                <div class="title_news">
                    <h1>{{main_news.title}}</h1>
                </div>
                <div class="desc_news main_desc_n" v-html="main_news.desc"></div>
                  <router-link class="btn_read" :to="`/news/${main_news.id}`">
                      <span> اقرأ المزيد</span>
                      <i class="bx bxs-chevrons-left"></i>
                    </router-link>
            </div>
        </div>
    </div>
    </template>
    <div class="more_news">
        <div class="title_page">
      <h1>{{ translate_str("More News") }}</h1>
    </div>
    <div class="row rowup2">
        <div class="col-lg-4" v-for="other_news in slice_news.slice(1)" :key="other_news.id">
            <router-link :to="`/news/${other_news.id}`" class="box_news">
                <vs-card type="1">
    <template #title>
      <h3>{{other_news.title}}</h3>
    </template>
    <template #img>
      <img :src="other_news.cover" :alt="other_news.title">
    </template>
    <template #text>
      <p class="desc_others" v-html="other_news.desc"></p>
      
    </template>
    <template #interactions>
      <vs-button class="btn-chat" shadow danger>
       <i class='bx bx-calendar'></i>
        <span class="span date_sp">
          {{other_news.date}}
        </span>
      </vs-button>
    </template>
  </vs-card>
            </router-link>
        </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "AllNewsApp",
  data(){
    return{
       percent:0,
       color:'#ed1e24',
    }
  },
   computed:{
     slice_news(){
      return Array.prototype.slice.call(this.get_all_news);
    },
           ...mapGetters({
               get_mystring:'get_mystring',
               get_setting: "get_setting",
               get_all_news:'get_all_news',
               get_language_list:'get_language_list'
           })
        },
        methods:{
          ...mapActions({
            all_news_api:'all_news_api'
          }),
                translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
        },
        mounted(){
           const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.all_news_api().then(()=>{
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });

        }
};
</script>

<style lang="scss">
.all_news_wrap{
   padding-top: 140px;
    padding-bottom: 80px;
       background-image: url('~@/assets/body.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    min-height: 100vh;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
      .btn_read {
      background-image: url("~@/assets/btn.png");
      padding: 12px 28px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      margin-top: 20px;
      font-size: 17px;
      position: relative;
      z-index: 22;
      color: white;
      i {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .main_desc_n{
      overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    div{
      // line-height: unset !important;
      font-family: inherit;
    }
    }
    .rowup{
        padding-top: 70px;
    }
    .rowup2{
        padding-top: 30px;
    }
    .img_news {
    text-align: right;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -8%;
      background-image: url("~@/assets/bord1.png");
         background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 115%;
    z-index: 0;
    background-position: right;
      
    }
    &::after{
         content: "";
      position: absolute;
        left: -7%;
         top: 7%;
      background-image: url("~@/assets/news3.png");
       background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 12%;
    z-index: 1;
    }
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-radius: 10px;
      padding-right: 60px;
      position: relative;
      z-index: 1;
    }
  }
  .text_news{
    padding-right: 50px;
    .date_news{    
    font-size: 17px;
    color: black;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    }
    .title_news{
        h1{
            color: var(--active_color);
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 20px;
        }
        
    }
    .desc_news{
      color: black;
        p{
            font-size: 15px;
            font-weight: 500;
            color: black;
            margin: 12px 0px;
        }
    }
  }
   .more_news{
    margin-top: 80px;
    .vs-card{
        width: 100%;
        max-width: 96%;
    }
    .vs-card__title{
        margin-bottom: 10px;
    }
    .date_sp{
    padding-right: 4px;
   }
   }
   
   .desc_others{
     overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
   }
}
@media(max-width:992px){
  .all_news_wrap .img_news{
    margin-bottom: 50px;
    img{
      width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
    padding-right: 20px;
    position: relative;
    z-index: 1;
    }
  }
  .all_news_wrap .text_news {
    padding-right: 0px;
 .title_news h1 {
    font-size: 1.5rem;
}
.desc_news p {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin: 8px 0px;
}
}
 .btn_read {
      padding: 12px 28px;
      margin-top: 20px;
      font-size: 15px;
      i {
        margin-right: 10px;
        vertical-align: middle;
      }
 }
}
</style>
