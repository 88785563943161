<template>
  <div class="home_wraps">
    <div class="sound_fixed">
      <div class="sound1" v-show="!sound_icon" @click="stop_sound">
        <i class='bx bx-volume-full bx-flashing' ></i>
      </div>
      <div class="sound2" v-show="sound_icon" @click="play_sound">
        <i class='bx bx-volume-mute bx-flashing' ></i>
      </div>   
    </div>
    <div class="main_slider_home">
      <div
        class="uk-position-relative uk-visible-toggle uk-light main_slider_home"
        tabindex="-1"
        uk-slideshow
      >
      <!-- ="autoplay: false;autoplay-interval: 8000;pause-on-hover: false" -->
        <ul class="uk-slideshow-items">
          <template v-for="slide in get_homepage_list.sliders">
            <template  v-if="slide.bg_media.type=='video'">
                <li :key="slide.id">
            <video class="vid_uks" :src="slide.bg_media.link" autobuffer muted playsinline loop autoplay alt=""  />
            <div class="container">
              <div class="row row_slider_pd">
                <div class="col-lg-6">
                  <div class="text_slider">
                    <vue-typed-js
                      :strings="slide.title_arr"
                      :loop="true"
                      :backSpeed="60"
                    >
                      <h1><span class="typing"></span></h1>
                    </vue-typed-js>
                    <p>
                     {{slide.desc}}
                    </p>
                    <template v-if="slide.btn.type == 'in'">
                      <router-link v-if="slide.btn.link" class="btn_read" :to="slide.btn.link">
                      <span> {{slide.btn.name}}</span>
                      <i class="bx bxs-chevrons-left"></i>
                    </router-link>
                    </template>
                    <template v-else>
                      <a v-if="slide.btn.link" class="btn_read" :href="slide.btn.link" target="_black">
                      <span> {{slide.btn.name}}</span>
                      <i class="bx bxs-chevrons-left"></i>
                      </a>
                    </template>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="img_slider">
                    <img :src="slide.side_img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </li>
            </template>
            <template v-else>
               <li :key="slide.id">
              <img class="img_uks" :src="slide.bg_media.link" alt="" uk-cover />
            <div class="container">
              <div class="row row_slider_pd">
                <div class="col-lg-6">
                  <div class="text_slider">
                    <vue-typed-js
                      :strings="slide.title_arr"
                      :loop="true"
                      :backSpeed="60"
                    >
                      <h1><span class="typing"></span></h1>
                    </vue-typed-js>
                    <p>
                   {{slide.desc}}
                    </p>
                    <template v-if="slide.btn.type == 'in'">
                      <router-link v-if="slide.btn.link" class="btn_read" :to="slide.btn.link">
                      <span> {{slide.btn.name}}</span>
                      <i class="bx bxs-chevrons-left"></i>
                    </router-link>
                    </template>
                    <template v-else>
                      <a v-if="slide.btn.link" class="btn_read" :href="slide.btn.link" target="_black">
                      <span> {{slide.btn.name}}</span>
                      <i class="bx bxs-chevrons-left"></i>
                      </a>
                    </template>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="img_slider">
                    <img :src="slide.side_img" alt="" />
                  </div>
                </div>
              </div>
            </div>
              </li>
            </template>
          </template>
        </ul>

        <a
          class="uk-position-center-left uk-position-small "
          href="#"
          uk-slidenav-previous
          uk-slideshow-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small "
          href="#"
          uk-slidenav-next
          uk-slideshow-item="next"
        ></a>
      </div>
    </div>
    <div class="maneger_wrap" data-aos="fade-up" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text_plans" v-if="get_homepage_list.director_peech">
              <h1> {{get_homepage_list.director_peech.title}} </h1>
              <p v-html="get_homepage_list.director_peech.desc"></p>
              <!-- <p>
                {{get_mystring.manp2}}
              </p> -->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="img_plans" v-if="get_homepage_list.director_peech">
              <img :src="get_homepage_list.director_peech.image" alt="shaddad" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end maneger -->
    <!-- partners -->
    <div class="partners">
      <div class="container">
        <div class="col-12">
          <div class="title_sec">
            <h1> {{ translate_str("Partners") }}</h1>
          </div>
          <div class="row">
            <div
              class="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              :data-aos-delay="300*index"
              v-for="(owner,index) in get_homepage_list.partners" :key="owner.id"
            >
              <vs-card>
                <template #title>
                  <h3 class="pt-3">
                    {{owner.name}}
                    </h3>
                </template>
                <template #img>
                  <img :src="owner.image" alt="" />
                </template>
                <template #text>
                  <p></p>
                </template>
              </vs-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- last News -->
    <div class="last_news_wrap" v-if="get_homepage_list.latest_news" :style="`background-image: url(${get_homepage_list.latest_news.bg_media});`" data-aos="fade-up" data-aos-duration="1000">
      <div class="container">
        <div class="title_sec">
          <!-- string api -->
           <h1> {{ translate_str("Latest News") }}</h1>
        </div>
        <div class="row">
          <div
            class="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <div class="last_news_text">
              <span class="date_news">{{get_homepage_list.latest_news.date}}</span>
              <div class="name_news">{{get_homepage_list.latest_news.title}}</div>
              <div class="desc_news" v-html="get_homepage_list.latest_news.desc">
              </div>
              <router-link  to="/news">
                     <span>{{translate_str("read more")}}</span>
                      <i class="bx bxs-chevrons-left"></i>
                    </router-link>
            </div>
          </div>
          <div
            class="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <div class="last_news_img">
              <img :src="get_homepage_list.latest_news.cover" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- feedback -->
    <div class="feedback_wrap">
      <div class="container">
        <div class="title_sec">
         <h1> {{ translate_str("Company FeedBack") }}</h1>
        </div>
        <!-- slider feedback -->
         <div class="user_say">
         <div class="slider_say">
                <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider
                 data-aos="fade-up"
          data-aos-duration="2000">
      
                    <ul class="uk-slider-items uk-child-width-1 uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-3@l uk-grid">
                        <li v-for="user in get_homepage_list.ratings" :key="user.id">
                            <div class="uk-panel">
                            <div class="box_user_say">
                                <img class="bitmap" src="~@/assets/Bitmap.svg" alt="bitmap">
                                <div class="text_say">
                                    <p v-html="user.content"></p>
                                </div>
                                <div class="user_rate_name">
                                    <div class="ratex">
                                        <b-rate 
                                        v-model="user.rate"
                                        icon-pack="fas" 
                                        ></b-rate>
                                    </div>
                                    <div class="username_say">
                                       <p>{{user.first_name}} {{user.last_name}}</p>
                                    </div>
                                </div>
                                <div class="img_profile">
                                    <img src="~@/assets/emp.png" alt="username">
                                </div>
                            </div>
                            </div>
                        </li>
                   
                    </ul>
                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>
            </div>
    </div>
      </div>
    </div>
    <!-- faq -->
    <div class="faq_wrap" :style="`background-image: url('${get_setting.faq_image}')`">
      <div class="container">
        <div class="title_sec">
         <h1> {{ translate_str("Common questions") }}</h1>
          <router-link to="/faq">
            <i class="bx bxs-chevrons-left"></i>
            <span>{{ translate_str("See More") }}</span>
          </router-link>
        </div>
        <!-- new faq -->
         <div class="faq_section" data-aos="fade-up"
          data-aos-duration="2000">
   <div class="row">
    <div class="col-lg-12">
         <div class="faq_accord">
                <ul uk-accordion="multiple: false">
                  <template v-for="faq in get_homepage_list.faqs">
                     <li :class="(faq.open == true?'uk-open':'')" :key="faq.id">
                        <a class="uk-accordion-title" href="#">
                          {{faq.question}}
                        </a>
                        <div class="uk-accordion-content">
                           <p v-html="faq.answer"></p>
                        </div>
                    </li>
                  </template>
                </ul>
    </div>
    </div>
   </div>
    </div>
      </div>
    </div>
    <!-- contact us -->
    <div
      class="contact_wrap"
      data-aos="fade-up"
      data-aos-duration="2000"
      data-aos-delay="600"
    >
      <div class="container">
        <div class="title_sec">
          <h1> {{ translate_str("Connect Us") }}</h1>
        </div>
        <!-- row conatct -->
        <div class="row">
          <div class="col-lg-6">
            <div class="maps">
              <GmapMap
                ref="mapss"
                :center="center"
                :zoom="13"
                style="width: 100%; height: 400px"
              >
                <GmapMarker :position="center" :draggable="false" />
                <!-- icon="~@/assets/logo.png" -->
                <!--     icon= "https://24order.no/storage/app/uploads/public/612/3fb/004/6123fb004e37b780670542.png" -->
              </GmapMap>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info_contact">
              <div class="box_infos">
                <div class="ico_info">
                  <img src="~@/assets/ico1.png" alt="" />
                </div>
                <div class="text_info">
                  <div class="title">
                    <span>{{get_mystring.cont1}}</span>
                  </div>
                  <div class="desc_info">
                  <span>{{ translate_str("Phone") }} - {{get_setting.phone}} {{ translate_str("fax") }} -{{get_setting.fax}}</span>
                     <span>{{ translate_str("email") }} -{{get_setting.email}}</span>
                  </div>
                </div>
              </div>
              <div class="box_infos active">
                <div class="ico_info">
                  <img src="~@/assets/ico2.png" alt="" />
                </div>
                <div class="text_info">
                  <div class="title">
                   <span>{{ translate_str("Address") }}</span>
                  </div>
                  <div class="desc_info">
                     <span>{{get_setting.address}}</span>
                  </div>
                </div>
              </div>
              <div class="box_infos">
                <div class="ico_info">
                  <img src="~@/assets/ico3.png" alt="" />
                </div>
                <div class="text_info">
                  <div class="title">
                   <span>{{ translate_str("Worktime") }}</span>
                  </div>
                  <div class="desc_info">
                     <span>{{get_setting.work_time}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- video company -->
    <div
      class="video_wraps"
      data-aos="fade-up"
      data-aos-duration="2000"
      data-aos-delay="600"
    >
      <div class="container">
        <div class="row" v-if="get_homepage_list.address_video">
          <div class="col-lg-6">
            <div class="text_video">
              <div class="title_sec">
               <h1> {{get_homepage_list.address_video.title}} </h1>
                <p v-html="get_homepage_list.address_video.desc"></p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="video_img">
              <video
                :src="get_homepage_list.address_video.video"
                autoplay
                loop
                autobuffer
                muted
                playsinline
              ></video>
              <div class="ripple_a">
                <div class="video-main" uk-lightbox>
                  <div class="promo-video">
                    <div class="waves-block">
                      <div class="waves wave-1"></div>
                      <div class="waves wave-2"></div>
                      <div class="waves wave-3"></div>
                    </div>
                  </div>
                  <a
                    :href="get_homepage_list.address_video.video"
                    data-type="video"
                    class="video video-popup mfp-iframe"
                    data-attrs="width: 1280; height: 620;"
                  >
                    <ion-icon name="play-outline"></ion-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import { VueTypedJs } from "vue-typed-js";
 import $ from 'jquery';
export default {
  name: "HomeApp",
  components: {
    VueTypedJs,
  },
  data() {
    return {
      center: { lat: 0, lng: 0 ,},
       percent:0,
       sound_icon:true,
      color:'#ed1e24',
    };
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
               get_homepage_list:'get_homepage_list',
               get_setting:'get_setting',
               get_language_list: "get_language_list",
           })
        },
  methods:{
    ...mapActions({
      homepage_api:'homepage_api'
    }),
    split_lang_lng() {
      this.center.lat = this.get_setting.latitude * 1;
      this.center.lng = this.get_setting.longitude * 1;
    },
     translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
    stop_sound(){
      console.log('stop sound');
      this.sound_icon = true;
      var $items = document.querySelector('.main_slider_home').getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', true) ;
          }
           this.uk.util.on('.main_slider_home', 'itemshown', function() {
        console.log(' show slider');
        var $items = this.getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', true) ;
          }
       });
       this.uk.util.on('.main_slider_home', 'beforeitemhide', function() {
        console.log(' before hide on StT func');
        var $items = this.getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', true) ;
          }
       });
    },
    play_sound(){
      console.log('play sound');
      this.sound_icon = false;
      var $items = document.querySelector('.main_slider_home').getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', false) ;
          }

       this.uk.util.on('.main_slider_home', 'itemshown', function() {
        console.log(' show slider on play func');
        var $items = this.getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', false) ;
          }
       });
       this.uk.util.on('.main_slider_home', 'beforeitemhide', function() {
        console.log(' before hide on play func');
        var $items = this.getElementsByClassName('uk-slideshow-items');
         var $activeItem = $items[0].getElementsByClassName('uk-active');
          if($($activeItem).find('video')){
            $($activeItem).find('video').prop('muted', true) ;
          }
       });
    }
  },
  mounted(){
          const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.homepage_api().then(()=>{
            this.split_lang_lng();
            const uk = this.uk;
            uk.slideshow($('.main_slider_home'), {
              'index':0,
              'animation':'fade',
            });
            // this.play_sound();
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
  }
};
</script>

<style scoped lang="scss">
.sound_fixed{
  position: fixed;
    left: 20px;
    bottom: 30px;
    z-index: 9999;
    font-size: 40px;
    color: white;
    background-color: var(--active_color);
    width: 55px;
    height: 55px;
    border-radius: 1000%;
    text-align: center;
    filter: drop-shadow(0px 0px 2rem #0003);
    cursor: pointer;
}
.uk-slideshow-items {
  min-height: 100vh !important;
  .img_uks {
    min-height: 100vh !important;
  }
  .vid_uks{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.main_slider_home {
  li {
    &::before {
      background: url("~@/assets/c1.png");
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      //background: linear-gradient(90deg, #636363, white);
      background: linear-gradient(90deg, #4a4a4a70, black);
      height: 100%;
      width: 100%;
      opacity: 0.4;
    }
  }
}
.row_slider_pd {
  z-index: 99;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  .text_slider {
    position: relative;
    padding-right: 20px;
    &::after {
      content: "";
      background-image: url("~@/assets/bord1.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      z-index: 2;
      width: 100%;
      height: 150%;
      top: -20%;
      background-position: right;
    }
    h1 {
      font-size: 2.6rem;
      color: var(--active_color);
      font-family: "Cairo", sans-serif;
      position: relative;
      z-index: 22;
      font-weight: 700;
    }
    p {
      font-size: 18px;
      color: white;
      position: relative;
      z-index: 22;
      font-weight: 500;
      text-shadow: 0px 1px 1px white;
    }
    .btn_read {
      background-image: url("~@/assets/btn.png");
      padding: 12px 28px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      margin-top: 10px;
      font-size: 17px;
      position: relative;
      z-index: 22;
      i {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .img_slider {
    text-align: left;
    position: relative;
    padding-left: 24px;
    margin-bottom: 80px;
    &::before {
      content: "";
      background-image: url("~@/assets/bord2.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      z-index: -1;
      width: 100%;
      height: 120%;
      top: -10%;
      background-position: left;
    }
    img {
      //width: 550px;
      width: 100%;
      height: 320px;
      object-fit: cover;
      object-position: left;
      border-radius: 10px;
    }
  }
}

// sec1
.maneger_wrap {
  padding-top: 50px;
  background-color: white;
  padding-bottom: 125px;
  &::after {
    content: "";
    background-image: url("~@/assets/cur.png");
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .row {
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .text_plans {
    h1 {
      color: var(--active_color);
      font-size: 2.1rem;
      font-weight: 600;
      position: relative;
      &::before {
        content: "";
        background-image: url("~@/assets/xb.png");
        position: absolute;
        right: -20px;
        top: -20px;
        width: 100%;
        height: 100%;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    p {
      font-size: 18px;
      color: black;
    }
  }
  .img_plans {
    text-align: left;
    position: relative;
    padding-left: 30px;
    img {
      width: 94%;
      border-radius: 10px;
      height: auto;
      position: relative;
    }
    &::before {
      content: "";
      background-image: url("~@/assets/bord3.png");
      position: absolute;
      left: 0;
      top: 10%;
      width: 100%;
      height: 80%;
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
    }
  }
}
.title_sec {
  h1 {
    color: var(--active_color);
    font-size: 2.1rem;
    font-weight: 600;
    position: relative;
    &::before {
      content: "";
      background-image: url("~@/assets/xb.png");
      position: absolute;
      right: -20px;
      top: -20px;
      width: 100%;
      height: 100%;
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
.partners {
  padding-top: 60px;
  background-color: white;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    background-image: url("~@/assets/sh1.png");
    background-position: center center;
    background-size: contain;
    bottom: 0;
    right: 1%;
    background-repeat: no-repeat;
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    background-image: url("~@/assets/sh2.png");
    background-position: center center;
    background-size: contain;
    top: 30%;
    left: 1%;
    background-repeat: no-repeat;
    z-index: 2;
  }
  .row {
    padding-top: 20px;
    position: relative;
    .col-lg-4 {
      position: relative;
      z-index: 3;
    }
    &::after {
      content: "";
      content: "";
      position: absolute;
      left: -6%;
      top: 30%;
      background-color: var(--bg_color1);
      width: 106%;
      height: 70%;
    }
  }
}
.last_news_wrap {
  // background-image: url("~@/assets/news.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  .row {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  .title_sec {
    h1 {
      color: #fff;
      &::before {
        content: "";
        background-image: url("~@/assets/xr.png");
      }
    }
  }
  .last_news_text {
    padding-top: 40px;
    color: white;
    .date_news {
      font-size: 16px;
      font-weight: 500;
    }
    .name_news {
      font-size: 25px;
      margin: 14px 0px;
      font-weight: 600;
    }
    .desc_news {
      font-size: 16px;
      font-weight: 500;
    }
    a {
      color: var(--active_color);
      background-color: #fff;
      padding: 8px 20px;
      display: inline-block;
      margin-top: 25px;
      font-size: 15px;
      font-weight: 600;
      border-radius: 4px;
      transition: 0.2s ease-in-out;
      i {
        vertical-align: middle;
        padding-right: 8px;
      }

      &:hover {
        background-color: var(--active_color);
        color: #fff;
      }
    }
  }
  .last_news_img {
    text-align: left;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 40%;
      background-image: url("~@/assets/news3.png");
      background-size: 120px;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-radius: 10px;
      padding-left: 60px;
      position: relative;
      z-index: 1;
    }
  }
}
.feedback_wrap{
   padding-top: 80px;
    padding-bottom: 40px;
    background-color: #ebebeb;
    position: relative;
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
     .form_emp{
    background-color: #FFFFFF;
    padding: 30px 14px 20px 14px;
    border-radius: 14px;
    }
    .title_form{
      h1{
        color: var(--active_color);
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 20px;
        padding-right: 10px;
      }
    }
    .row_up{
      padding-top: 50px;
      display: flex;
      align-items: center;
      .formpd{
        margin-bottom:30px;
      }
      textarea{
        height: 120px;
        width: 100%;
      }
        .img_feedback{
            width: 85%;
            margin: 0 auto;
            position: relative;
            padding-left: 20px;
            transform: rotate(-5deg);
             .pin{
 position: absolute;
    width: 35px;
    height: 35px;
     right: -4px;
    top: -20px;
    z-index: 3;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    }
            &::before{
                content: '';
                background-image: url('~@/assets/bord1.png');
                background-position: right;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 80%;
                top: 10%;
                left: 0;
                transform: scaleX(-1);
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                position: relative;
            }
        }

    }

//     .slider_say{
//      direction: ltr;
//     text-align: left;
//     margin-top: 50px;
// }
.user_say .title_user{
    text-align: center;
    color: #FFFFFF;
}
.user_say .title_user h1{
font-weight: 700;
font-size: 36px;
color: #FFFFFF;
}
.user_say .title_user h2{
    font-weight: 400;
    font-size: 38px;
    color: #FFFFFF;
    }
    .user_say .title_user p{
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        }
    .box_user_say{
        background: #fff;
        box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
        border-radius: 100px 24px 24px 24px;
        padding: 65px 20px 20px 20px;
        width: 100%;
        direction: rtl;
    text-align: right;
    position: relative;
    margin: 0 auto;
    transition: 0.3s linear;
    }
    .box_user_say:hover{
      background-color: rgb(242, 242, 242);
    }
    .box_user_say .text_say p{
    font-weight: 500;
    font-size: 15px;
    color: black;
      overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 68px;
    }
    .box_user_say .username_say p {
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        padding-left: 15px;
        margin: 0;
    }
    .box_user_say .user_rate_name{
        margin-top: 40px;
        text-align: left;
        direction: rtl;
    }
    .box_user_say .ratex .star_r  i{
       color: #DCDCDC;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        width: 18px;
        height: 17px;
        font-weight: 600;
    }
    .box_user_say .ratex .star_r.fill i{
        color: #FFA500;
    }
    .user_say .uk-slider-items.uk-grid {
        flex-wrap: nowrap;
        padding-bottom: 60px;
    }
    .user_say .img_profile{
        position: absolute;
       top: 80%;
    right: 10%;
    }
    .user_say .img_profile img{
    box-shadow: 0px 16px 24px rgb(0 24 52 / 8%);
    border-radius: 100%;
    width: 90px;
    height: 90px;
    padding: 5px;
    border: 5px solid gainsboro;
    background-color: white;
    object-fit: cover;
    }
    .user_say .bitmap{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 20px;
        top: 20px;
    }
}


.faq_wrap {
  // background-image: url("~@/assets/faq.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  padding-top: 60px;
  padding-bottom: 10px;
  direction: ltr;
  // border-bottom-left-radius: 200px;
  // border-top-left-radius: 200px;
  &::before {
    content: "";
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  .row {
    position: relative;
    z-index: 2;
  }
  .title_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    h1 {
      color: #fff;
      &::before {
        content: "";
        background-image: url("~@/assets/xr.png");
      }
    }
    a {
      color: var(--active_color);
      background-color: #fff;
      padding: 8px 20px;
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      border-radius: 4px;
      transition: 0.2s ease-in-out;
      position: relative;
      margin-top: -18px;
      i {
        vertical-align: middle;
        padding-right: 8px;
      }

      &:hover {
        background-color: var(--active_color);
        color: #fff;
      }
    }
  }
  .uk-slider-items.uk-grid {
    flex-wrap: nowrap;
    padding: 22px 0px;
    padding-bottom: 130px;
  }
  .faq_box {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.075);
    border-radius: 10px;
    padding: 40px 20px 25px 20px;
    transition: 0.2s linear;
    &:hover {
      background-color: #fff;
      .icon_faq {
        img {
          filter: brightness(1) invert(0);
        }
      }
      .desc_faq {
        p {
          color: black;
        }
      }
      .name_faq {
        color: var(--active_color);
      }
    }
    .icon_faq {
      img {
        filter: brightness(0) invert(1);
        transition: 0.2s linear;
      }
    }
    .desc_faq {
      p {
        font-size: 14px;
        color: white;
        font-weight: 500;
        margin: 14px 0px;
        text-align: right;
        transition: 0.2s linear;
      }
    }
    .name_faq {
      font-size: 14px;
      color: white;
      font-weight: 500;
      padding-top: 20px;
      transition: 0.2s linear;
    }
  }
// new faq ddl
.faq_section .uk-accordion-title{
       color: black;
    background: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 17px 40px 17px 14px;
    border: 1px solid gainsboro;
}
.faq_section .uk-accordion-content{
    margin-top: 0px;
    padding: 30px;
    background-color: #fff;
    border-top: 1px solid gainsboro;
}
.faq_section .uk-accordion-content p{
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.faq_section .uk-accordion-title::before {
    content: "";
    width: 1.2em;
    height: 1.2em;
    margin-left: 10px;
    float: left;
    background-image: url('~@/assets/arrow.svg');
        background-position: center center;
    background-size: contain;
    filter: invert(1);
}
.faq_section .faq_accord{
   padding-top: 30px;
    padding-bottom: 55px;
}
.faq_section .more_faq{
    text-align: center;
    margin-top: 90px;
}
.faq_section .more_faq a{
font-weight: 700;
font-size: 26px;
color: #FFFFFF;
border: 1.5px solid #FF6433;
border-radius: 15px;
padding: 4px 43px;
transition: 0.3s ease-in-out;
}
.faq_section .more_faq a:hover{
    font-weight: 700;
    font-size: 26px;
    background-color: #FF6433;
    border: 1.5px solid #fff;
    }

}
.contact_wrap {
  padding-top: 80px;
  padding-bottom: 20px;
  .box_infos {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    border: 1px solid #cccccc;
    background-color: white;
    border-radius: 10px;
    padding: 20px 10px;
    min-height: 115px;
    transition: 0.2s ease-in-out;
    // &:hover {
    //   background-color: var(--active_color);
    //   .ico_info {
    //     img {
    //       filter: brightness(0) invert(1);
    //     }
    //   }
    //   .text_info {
    //     .title {
    //       span {
    //         color: #fff;
    //         transition: 0.2s ease-in-out;
    //       }
    //     }
    //     .desc_info {
    //       span {
    //         color: #fff;
    //         transition: 0.2s ease-in-out;
    //       }
    //     }
    //   }
    // }
    .ico_info {
      img {
        width: 28px;
        height: 28px;
        object-fit: contain;
        margin-top: 2px;
        transition: 0.2s ease-in-out;
      }
    }
    .text_info {
      padding-right: 8px;
      .title {
        margin-bottom: 8px;
        margin-top: -6px;
        span {
          font-size: 18px;
          color: var(--active_color);
          font-weight: 600;
        }
      }
      .desc_info {
        padding-top: 4px;
        span {
          display: block;
          font-size: 14px;
        }
      }
    }
  }
  .box_infos.active{
      background-color: var(--active_color);
      .ico_info {
        img {
          filter: brightness(0) invert(1);
        }
      }
      .text_info {
        .title {
          span {
            color: #fff;
            transition: 0.2s ease-in-out;
          }
        }
        .desc_info {
          span {
            color: #fff;
            transition: 0.2s ease-in-out;
          }
        }
      } 
    }

    // new faq
}

.video_wraps {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
  .row {
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .title_sec {
    position: relative;
    z-index: 2;
  }
  .text_video {
    &::before {
      content: "";
      position: absolute;
      right: -2%;
      top: 24%;
      background-color: #ebebeb;
      width: 100%;
      height: 60%;
    }
  }
  .video_img {
    position: relative;
    // text-align: left;
    background-color: #fff;
    z-index: 2;
    &::before {
      content: "";
      background-color: #000;
      position: absolute;
      right: 0;
      top: 0;
      width: 90%;
      height: 100%;
      opacity: 0.7;
      border-radius: 10px;
    }
    &::after {
      content: "";
      background-image: url("~@/assets/bord4.png");
      background-size: contain;
      background-position: left;
      background-repeat: no-repeat;
      position: absolute;
      left: 0%;
      top: 6.5%;
      width: 100%;
      height: 88%;
    }
    video {
      border-radius: 10px;
      margin-left: 40px;
      width: 90%;
      object-fit: cover;
    }

    //
    .ripple_a {
      position: absolute;
      top: 50%;
      left: 10%;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      transform: translate(0px, -50%);
      z-index: 20;
    }

    .video-main {
      position: relative;
      display: inline-block;
      z-index: 2;
      text-align: center;
    }
    .video {
      height: 50px;
      width: 50px;
      line-height: 50px;
      // text-align: center;
      border-radius: 100%;
      background: transparent;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--active_color);
      z-index: 2;
      transition: 0.3s linear;
    }

    .video:hover {
      color: #fff;
    }

    @keyframes waves {
      0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      }
      50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
      }
      100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      }
    }

    .fa-play:before {
      content: "\f04b";
    }

    .waves {
      position: absolute;
      width: 150px;
      height: 150px;
      background: var(--active_color);
      /*background: rgba(0, 0, 0, 0.3);*/
      opacity: 0;
      -ms-filter-: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      border-radius: 100%;
      right: -50px;
      bottom: -50px;
      z-index: -1;
      -webkit-animation: waves 3s ease-in-out infinite;
      animation: waves 3s ease-in-out infinite;
    }

    .wave-1 {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    .wave-2 {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }

    .wave-3 {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }
    /* end */
    // end ripple
  }
}

// Media Query

@media(min-width:1600px){
  .main_slider_home {
  li {
    &::before {
      bottom: -11px;
    }
  }
  }
}
@media (max-width: 1399px) {
  .row_slider_pd .text_slider h1 {
    font-size: 2.2rem;
  }
  .row_slider_pd .text_slider p {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .row_slider_pd .text_slider h1 {
    font-size: 1.9rem;
  }
  .row_slider_pd .text_slider p {
    font-size: 15px;
  }
  .row_slider_pd {
    padding: 0px;
  }
}
@media (max-width: 992px) {
  .row_slider_pd {
    padding-top: 120px;
  }
  .row_slider_pd .text_slider h1 {
    font-size: 1.9rem;
  }

  .row_slider_pd .text_slider p {
    font-size: 15px;
  }
  .row_slider_pd .img_slider img {
    width: 100%;
    height: 190px;
  }
  .main_slider_home li::before {
    bottom: -4px;
  }
  .maneger_wrap .text_plans p {
    font-size: 16px;
  }
  .img_plans {
    margin-top: 40px;
  }
  .info_contact {
    margin-top: 40px;
  }
  .last_news_wrap .last_news_img {
    margin-top: 60px;
  }
  .video_wraps .video_img {
    margin-top: 55px;
  }
  .video_wraps .text_video::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .row_slider_pd .text_slider h1 {
    font-size: 1.5rem;
  }
  .row_slider_pd .text_slider p {
    font-size: 14px;
  }
  .row_slider_pd .text_slider .btn_read {
    font-size: 14px;
  }
  .row_slider_pd .text_slider {
    margin-bottom: 30px;
  }
  .title_sec h1,
  .maneger_wrap .text_plans h1 {
    font-size: 1.4rem;
    padding-right: 20px;
  }
  .title_sec h1::before,
  .maneger_wrap .text_plans h1::before {
    right: 0px;
  }
  .maneger_wrap .text_plans p {
    font-size: 15px;
  }
  .maneger_wrap {
    padding-top: 50px;
    padding-bottom: 45px;
  }
  .partners {
    padding-top: 30px;
  }
  .last_news_wrap .last_news_img img {
    width: 100%;
    height: 250px;
  }
  .last_news_wrap .last_news_text .name_news {
    font-size: 18px;
  }
  .last_news_wrap .last_news_text .desc_news,
  .last_news_wrap .last_news_text .date_news {
    font-size: 14px;
  }
  .faq_wrap .title_sec a {
    font-size: 13px;
  }
  .feedback_wrap .box_feedback,
  .faq_wrap .faq_box {
    width: 90%;
    margin: 0 auto;
  }
  .faq_wrap .faq_box .desc_faq p,
  .feedback_wrap .box_feedback .desc_feed p {
    font-size: 13px;
  }
.faq_section .uk-accordion-title{
    font-size: 14px !important;
    padding: 17px 20px 17px 14px !important;
}
.faq_section .uk-accordion-content p{
    font-size: 13px !important;
    font-weight: 400;
}
}
</style>
