<template>
  <vs-navbar :class="['header_wraps', nav_scroll == true ? 'nav_scroll' : '']">
    <div class="nav_bar">
      <div class="mob_ico" uk-toggle="target:#offcanvas-sidebar">
        <i class="bx bx-menu-alt-right"></i>
      </div>
      <div class="parent_nav">
        <div class="logo">
          <router-link to="/"
            ><img :src="get_setting.logo" alt="shaddad logo"
          /></router-link>
        </div>
        <div class="nav_links web_links">
          <router-link to="/" class="link_a">
            <span>{{ translate_str("home") }}</span>
          </router-link>
          <router-link to="/products" class="link_a">
            <span>{{ translate_str("products") }}</span>
          </router-link>
          <router-link to="/media-center" class="link_a">
           <span>{{ translate_str("media center") }}</span>
          </router-link>
          <router-link to="/employees" class="link_a">
             <span>{{ translate_str("Employees") }}</span>
          </router-link>
          <a :href="get_setting.catalog" class="link_a" target="_blank">              
            <span>{{ translate_str("Catalog") }}</span>
             </a>
          <vs-navbar-group class="link_g">
            <span>{{ translate_str("About company") }}</span>
            <template #items>
              <vs-navbar-item>
                <router-link to="/company-history">
                 {{ translate_str("Company history") }}
                </router-link></vs-navbar-item
              >
              <vs-navbar-item>
                <router-link to="/news">
                {{ translate_str("News") }}
                </router-link></vs-navbar-item
              >
              <vs-navbar-item>
                <router-link to="/feedback">
                 {{ translate_str("Feedback") }}
                </router-link></vs-navbar-item
              >
              <vs-navbar-item>
                <router-link to="/faq">
                {{ translate_str("Common questions") }}
                </router-link></vs-navbar-item
              >
            </template>
          </vs-navbar-group>
        </div>
      </div>
      <div class="icons_nav">
        <div class="search_ico">
          <a  href="#modal-full" uk-toggle><i class="bx bx-search-alt"></i></a>
          
        </div>
        <!-- modal search -->
            <div id="modal-full" class="uk-modal-full uk-modal" uk-modal>
        <div class="uk-modal-dialog uk-flex uk-flex-center uk-flex-middle" uk-height-viewport>
            <button class="uk-modal-close-full" type="button" uk-close></button>
          <div class="container">
            <section class="w-100">
        <!-- <p class="content"><b>Selected:</b> {{ selected }}</p> -->
        <!--  rounded -->
          <p class="mb-2">{{translate_str("Search Now")}}</p>
        <b-field >
            <b-autocomplete
                :data="data_search"
                placeholder=""
                field="title"
                :loading="isFetching"
                @typing="getAsyncData"
                v-model="search_val"
                clearable
                @select="option => (selected = option) "
                >
                <template slot-scope="props">
                    <div class="media">
                        <!-- <div class="media-left">
                            <img width="32" :src="props.option.image">
                        </div> -->
                        <div class="media-content">
                            {{ props.option.name }}  {{ props.option.title }}
                            <br>
                            <!-- <small>
                                Released at {{ props.option.release_date }},
                                rated <b>{{ props.option.vote_average }}</b>
                            </small> -->
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
            </section>
          </div>
        </div>
            </div>
            <!-- modal search -->
        <template v-if="active_val == 'en'">
          <span @click="changeLang('er')" class="ar_span spanLang">
            <!-- <img src="~@/assets/ar.png" alt="" width="35"> -->
            ع
          </span>
        </template>
        <template v-else>
          <span @click="changeLang('en')" class="en_span spanLang">
            <!-- <img src="~@/assets/en.png" alt="" width="35"> -->
            En
          </span>
        </template>
      </div>
    </div>
    <div id="offcanvas-sidebar" uk-offcanvas>
      <div class="uk-offcanvas-bar">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <div class="logo_sidebar">
          <!-- <img :src="get_setting_app.logo" alt="logo"> -->
          <router-link to="/"
            ><img :src="get_setting.logo" alt="shaddad logo"
          /></router-link>
          <!-- <img
                    src="@/assets/logo.png"
                    alt="logo"
                > -->
        </div>
        <ul class="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
          <router-link to="/">
            <span>{{ translate_str("home") }}</span>
          </router-link>
          <router-link to="/products">
            <span>{{ translate_str("products") }}</span>
          </router-link>
          <router-link to="/media-center">
           <span>{{ translate_str("media center") }}</span>
          </router-link>
          <router-link to="/employees">
             <span>{{ translate_str("Employees") }}</span>
          </router-link>
          <a :href="get_setting.catalog" target="_blank">              
            <span>{{ translate_str("Catalog") }}</span>
             </a>
          <router-link to="/company-history">
           {{ translate_str("Company history") }}
          </router-link>
          <router-link to="/news">{{ translate_str("News") }}</router-link>
          <router-link to="/feedback"> {{ translate_str("Feedback") }}</router-link>
          <router-link to="/faq">{{ translate_str("Common questions") }}</router-link>
        </ul>
      </div>
    </div>
  </vs-navbar>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import debounce from 'lodash/debounce'
import $ from "jquery";
export default {
  name: "Header",
  data() {
    return {
      nav_scroll: false,
      active_val: "",
      search_val:'',
      data_search:[],
      selected: null,
      isFetching: false,
      clearable: true
    };
  },
  computed: {
    ...mapGetters({
      get_mystring: "get_mystring",
      get_setting: "get_setting",
      get_language_list: "get_language_list",
      get_search_list:'get_search_list'
    }),
    // show_search_result(){
    //   console.log(this.get_search_list)
    //   return this.get_search_list;
    // }
  },
  watch:{
    selected: {
       handler: function(oldVal) {
           this.select_search(oldVal)
       },
       deep: true
    }

  },
  methods: {
    ...mapActions({
      fill_text_json: "fill_text_json",
      search_api:"search_api"
    }),
    scroll_down_page() {
      document.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
          this.nav_scroll = true;
        } else {
          this.nav_scroll = false;
        }
      });
    },
    close_auto_side() {
      const link_side = document.querySelectorAll("#offcanvas-sidebar a");
      link_side.forEach((elem) => {
        elem.addEventListener("click", () => {
          this.uk.offcanvas($("#offcanvas-sidebar")).hide();
        });
      });
    },
    changeLang(language) {
      if (language == "en") {
        window.location.reload();
        this.active_val = "en";
        document.body.classList.add("ltr");
        document.documentElement.classList.add("ltr");
        localStorage.setItem("Fav_lang", "en");
        this.fill_text_json();
        
      } else {
        window.location.reload();
        this.active_val = "ar";
        document.body.classList.remove("ltr");
        document.documentElement.classList.remove("ltr");
        localStorage.setItem("Fav_lang", "ar");
        this.fill_text_json();
      }
    },
    translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
     getAsyncData: debounce(function (name) {
                if (!name.length) {
                    this.data_search = []
                    return
                }
                this.isFetching = true
                this.search_api(name).then(()=>{
              this.data_search= [];
              this.get_search_list.products.forEach(element => {
                this.data_search.push(element);
              });
              this.get_search_list.news.forEach(element => {
                this.data_search.push(element);
              });
              
            }).catch((error) => {
                        this.data_search = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
              },500),
    clearrName(){
      // this.search_val= ''
    },
    select_search(select_item){
      if(select_item !=null){
        this.selected = null;
      this.search_val= ''
      this.uk.modal($('#modal-full')).hide();
        if(Object.hasOwn(select_item, 'category_id')){
          this.$router.push({ path: `/products/${select_item.category_id}`});
        }else{
          this.$router.push({ path: `/news/${select_item.id}`}); 
        }
      }
    },
  },
  mounted() {

    if (localStorage.getItem("Fav_lang") == "en") {
      this.active_val = "en";
      document.body.classList.add("ltr");
      document.documentElement.classList.add("ltr");
      // 
       let htmlEl=document.querySelector("html");
        htmlEl.setAttribute('dir','ltr');
        htmlEl.setAttribute('lang','en');
    } else {
      this.active_val = "ar";
      document.body.classList.remove("ltr");
      document.documentElement.classList.remove("ltr");
      // 
        let htmlEl=document.querySelector("html");
        htmlEl.setAttribute('dir','rtl');
        htmlEl.setAttribute('lang','ar');
    }

    this.scroll_down_page();
    this.close_auto_side();
   
  },
};
</script>

<style lang="scss">
.nav_scroll {
  .vs-navbar {
    background-color: #fff;
    box-shadow: 0px 2px 1px gainsboro;
  }
}
.vs-navbar {
  width: 98% !important;
  position: fixed;
  left: 0;
  top: 20px;
  background-color: rgba(255, 255, 255, 0.835);
  opacity: 1;
  border-radius: 30px !important;
  transition: 0.2s ease-in-out;
  // border-bottom-left-radius: 30px;
  // border-top-left-radius: 30px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.header_wraps {
  .nav_links {
    padding-right: 60px;
    display: flex;
    align-items: center;
    .link_a,
    .link_g {
      color: black;
      padding: 0;
      font-size: 16px;
      font-weight: 600;
      margin-right: 34px;
      text-align: right;
      direction: rtl;
      position: relative;
      transition: 0.2s ease-in-out;
    }
    .link_a {
      &::after {
        content: "";
        width: 100%;
        height: 1.4px;
        background-color: var(--active_color);
        position: absolute;
        left: 0;
        bottom: -8px;
        opacity: 0;
        transition: 0.2s ease-in-out;
      }
    }
    .link_a.router-link-exact-active {
      color: var(--active_color);
      &::after {
        content: "";
        opacity: 1;
      }
    }
    .link_g .main_a::after {
      content: "";
      width: 100%;
      height: 1.4px;
      background-color: var(--active_color);
      position: absolute;
      left: 0;
      bottom: -8px;
      opacity: 0;
      transition: 0.2s ease-in-out;
    }
    .link_a:hover,
    .link_g:hover .main_a {
      color: var(--active_color);
      &::after {
        content: "";
        opacity: 1;
      }
    }
  }
  .vs-navbar__group__items {
    left: unset !important;
    right: 0 !important;
  }
  .vs-navbar__group__items .vs-navbar__item {
    text-align: right;
  }
}
.mob_ico {
  display: none;
  i {
    font-size: 30px;
    cursor: pointer;
  }
}
.spanLang {
  width: 30px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.icons_nav {
  display: flex;
  align-items: baseline;
  .search_ico {
    padding-left: 14px;
    i {
      font-size: 30px;
      cursor: pointer;
      color: black;
      vertical-align: middle;
      transition: 0.2s ease-in-out;
      &:hover{
        color: var(--active_color);
      }
    }
  }
  img {
    cursor: pointer;
    object-fit: contain;
  }
}

@media (max-width: 992px) {
  .header_wraps .web_links {
    display: none;
  }
  .mob_ico {
    display: block;
  }
  .header_wraps .nav_bar {
    align-items: center !important;
  }
  .mob_ico,
  .icons_nav {
    flex: 0 0 50px;
  }
  .logo {
    text-align: center;
    img {
      width: 70%;
    }
  }
}
</style>
