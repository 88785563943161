<template>
    <div class="single_blog_wrap" :style="`background-image: url('${get_setting.body_image}')`">
        <!--====================================
=            Single Article            =
=====================================-->
<section class="section blog-single">
	<div class="container">
		<div class="row">
			<div class="col-md-10 m-auto">
				<!-- Single Post -->
				<article class="single-post">
					<!-- Post title -->
					<div class="post-title text-center">
						<h1>{{get_news_info.title}}</h1>
						<!-- Tags -->
						<ul class="list-inline post-tag">
							<!-- <li class="list-inline-item">
								<img width="30" height="30" :src="get_setting.logo" alt="author-thumb">
							</li> -->
							<!-- <li class="list-inline-item">
								<a >add by Admin</a>
							</li> -->
							<li class="list-inline-item">
								<a ><i class='bx bx-calendar bx-flashing' ></i> {{get_news_info.date}}</a>
							</li>
						</ul>
					</div>
					<!-- Post body -->
					<div class="post-body">
						<!-- Feature Image -->
						<div class="feature-image slider_news">
              <div class="uk-position-relative uk-visible-toggle uk-light news_slider" tabindex="-1" uk-slideshow>

                  <ul class="uk-slideshow-items">
                      <template v-for="img in get_news_info.images">
                        <li :key="img.id">
                          <img :src="img.url" alt="" uk-cover>
                      </li>
                      </template>
                  </ul>

                  <a class="uk-position-center-left uk-position-small " href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                  <a class="uk-position-center-right uk-position-small " href="#" uk-slidenav-next uk-slideshow-item="next"></a>

                  </div>
							<!-- <img class="img-fluid" :src="get_news_info.image" alt="feature-image"> -->
						</div>
						<!-- Paragrapgh -->
						<p class="desc_black" v-html="get_news_info.desc"></p>
						
					</div>
				</article>
			</div>
		</div>
	</div>
</section>
<!--====  End of Single Article  ====-->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import $ from 'jquery';
export default {
     name:'singleBlogs',
    data(){
        return{
           percent:0,
            color:'#ed1e24',
        }
    },
      computed:{
    ...mapGetters({
        get_mystring:'get_mystring',
    get_setting: "get_setting",
       get_news_info:'get_news_info',
    })
    } ,
    methods:{
      ...mapActions({
        new_details_api:'new_details_api'
      })
    },
    mounted(){
          const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40)
          this.new_details_api(this.$route.params.id).then(()=>{
            this.uk.slideshow($('.news_slider'), {
              'index':0,
              // 'animation':'fade',
            });
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
    }
}
</script>
<style lang="scss" scoped>

.single_blog_wrap{
    padding-top: 140px;
    position: relative;
    z-index: 2;
    min-height: 100vh;
    padding-top: 140px;
    padding-bottom: 40px;
    background-image: url("~@/assets/body.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    .desc_black{
      color: #000 !important;
    }
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  @media(max-width:992px){
    h1 {
    font-size: 20px;
    line-height: initial;
    margin-bottom: 10px;
}
p{
  font-size: 14px;
}
  }
}
.blog-single .single-post {
  padding-bottom: 70px;
}
.blog-single .post-title{
   h1{
    line-height: 30px;
    margin-bottom: 10px;
   }
}

.blog-single .single-post .post-body .feature-image {
  margin-bottom: 30px;
}

.blog-single .single-post .post-body .feature-image img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.blog-single .single-post .post-body p {
  margin-bottom: 20px;
}

.blog-single .single-post .post-body .quote {
  padding: 30px 0;
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .blog-single .single-post .post-body .quote {
    width: 80%;
  }
}

.blog-single .single-post .post-body .quote blockquote {
  color: #000;
  padding: 10px 0 10px 30px;
  text-align: left;
  font-size: 30px;
  line-height: 40px;
  border-left: 6px solid #666666;
}

.blog-single .single-post .post-body .post-image {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.blog-single .about-author h2 {
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
  font-size: 30px;
}

@media (max-width: 480px) {
  .blog-single .about-author h2 {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .blog-single .about-author .media {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .blog-single .about-author .media .image {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.blog-single .about-author .media .image img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.blog-single .about-author .media .media-body {
  margin-left: 40px;
}

@media (max-width: 480px) {
  .blog-single .about-author .media .media-body {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 20px;
  }
}

.blog-single .about-author .media .media-body p {
  margin-bottom: 15px;
}

.related-articles .title {
  margin-bottom: 20px;
}

.related-articles .title h2 {
  font-size: 30px;
}
h1 {
  font-size: 25px;
  line-height: 61px;
}

h2 {
  font-size: 40px;
  line-height: 50px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
}

p, li, blockquote, label , a{
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  color: #808080 !important;
  margin-bottom: 0;
}
ul.post-tag {
    margin-bottom: 20px;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
ul.post-tag li {
    font-size: 14px;
}
ul.social-links {
  margin-bottom: 0;
}

ul.social-links li:first-child a {
  padding-left: 0;
}

ul.social-links li a {
  padding: 0 15px;
  display: block;
}

ul.social-links li a ion-icon {
  font-size: 20px;
  color: #000;
}


</style>