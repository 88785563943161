<template>
  <employeeInfoApp></employeeInfoApp>
</template>

<script>
import employeeInfoApp from '@/components/media/employeeInfoApp.vue';
export default {
  components: { employeeInfoApp },
  name: "EmployeesInfo",
    mounted(){
         document.body.classList.add('EmployeesInfo', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('EmployeesInfo', 'otherPage');
    }
};
</script>