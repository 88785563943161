<template>
<footer>
    <div class="container">
       <div class="row">
        <div class="col-lg-4">
            <div class="logo_foot">
                <img :src="get_setting.logo" alt="">
                <p v-html="get_setting.footer_desc"></p>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="links_foot">
                <h3>
                    {{ translate_str("Quick Links") }}
                </h3>
                <ul>
                    <li>
                        <router-link to="/">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                              <span>{{ translate_str("home") }}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/products">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                             <span>{{ translate_str("products") }}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/media-center">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                             <span>{{ translate_str("media center") }}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/employees">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                           <span>{{ translate_str("Employees") }}</span>
                        </router-link>
                    </li>
                    <li>
                        <a :href="get_setting.catalog" target="_blank"> 
                            <ion-icon name="chevron-back-outline"></ion-icon>
                            <span>{{ translate_str("Catalog") }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-4">
           <div class="social_wraps">
             <div class="socal_icon">
                <a v-if="get_setting.facebook!=null" :href="get_setting.facebook" target="_blank"> <ion-icon name="logo-facebook"></ion-icon></a>
                 <a v-if="get_setting.instagram!=null" :href="get_setting.instagram" target="_blank"> <ion-icon name="logo-instagram"></ion-icon></a>
                  <a v-if="get_setting.Linkedin!=null" :href="get_setting.Linkedin" target="_blank"> <ion-icon name="logo-linkedin"></ion-icon></a>
                   <a v-if="get_setting.whatsapp!=null" :href="`https://wa.me/${get_setting.whatsapp}`" target="_blank"> <ion-icon name="logo-whatsapp"></ion-icon></a>
            </div>
            <div class="fb_iframe">
                <!-- <div class="fb-page" :data-href="get_setting.iframe_footer" data-tabs="footer" data-width="300" data-height="220" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="false"><blockquote :cite="get_setting.iframe_footer" class="fb-xfbml-parse-ignore"><a :href="get_setting.iframe_footer"> {{get_setting.name}}</a></blockquote></div> -->
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fshadadd%2F&tabs=timeline&width=300&height=220&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="220" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
           </div>
            
        </div>
        </div> 
    </div>
    <div class="copy">
                <p><span class="site_name">{{get_setting.Copyright}}</span> Copyright © {{fullYear}} - Powered by Remak</p>
            </div>
            <!-- wpp-btn-mobile -->
<div class="phone-call cbh-phone cbh-green cbh-show  cbh-static" id="clbh_phone_div" style=""><a id="WhatsApp-button" 
    v-if="get_setting.whatsapp!=null" :href="`https://wa.me/${get_setting.whatsapp}`" target="_blank" class="phoneJs" title="WhatsApp 360imagem"><div class="cbh-ph-circle"></div><div class="cbh-ph-circle-fill"></div><div class="cbh-ph-img-circle1">
    <i class="bx bxl-whatsapp"></i>
</div></a></div>
<!-- wpp-btn-mobile -->
</footer>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: "footerApp",
  data(){
    return{
        fullYear:new Date().getFullYear()
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
                get_setting:'get_setting',
                get_language_list:'get_language_list'
           })
        },
        methods:{
    translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
        }
};
</script>

<style lang="scss">

footer{
//  padding: 40px 0px; 
 background-color: #eeeeee;
 background-image: url('~@/assets/foot.jpeg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    // .site_name{
    //     color:var(--active_color);
    //     font-weight:500;
    // }
    .row{
            padding-top: 220px;
    padding-bottom: 30px;
    }
    .logo_foot{
        p{
            margin: 10px 0px;
        }
    }
 .links_foot{
        width: 50%;
    margin: 0 auto;
    h3{
         color: var(--active_color);
         font-size: 1.5rem;
         margin-bottom: 20px;
    }
    ul{
        list-style: none;
        li{
            margin-bottom: 8px;
         a{
            color: black;
            &:hover{
                color: var(--active_color);
            }
           ion-icon{
             color: var(--active_color);
             vertical-align: middle;
             margin-top: -2px;
             padding-left: 8px;
           }
        }
        }
    }
 }  
 .social_wraps{
    text-align: center;
 .socal_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    a{
        margin-right: 12px;
        transition: 0.3s ease-in-out;
        &:hover{
          ion-icon{
        color: white;
        background-color: var(--active_color);
    }  
        }
        ion-icon{
        font-size: 30px;
        color: var(--active_color);
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 100%;
        padding: 6px;
        transition: 0.3s ease-in-out;
    }
    }
 }
}
.copy{
    text-align: center;
    background-color: #ffffff;
    color: black;
    padding: 10px;
    margin-top: 10px;
    p{
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
    }
}
}

@media(min-width:1750px){
 footer .row {
    padding-top: 320px !important;
    padding-bottom: 30px;
}
}
@media(min-width:1450px){
 footer .row {
    padding-top: 270px;
    padding-bottom: 30px;
}
}
@media(max-width:992px){
    footer .row {
    padding-top: 180px;
    padding-bottom: 30px;
}
.links_foot{
    width: 100% !important;
    margin: 20px 0px !important;
}
}
@media(max-width:767px){
footer .row {
    padding-top: 100px;
    padding-bottom: 30px;
}
}
</style>
