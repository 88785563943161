<template>
  <historyApp></historyApp>
</template>

<script>
import historyApp from '@/components/media/historyApp.vue';
export default {
  components: { historyApp },
  name: "History",
    mounted(){
         document.body.classList.add('historyApp', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('historyApp', 'otherPage');
    }
};
</script>