<template>
  <faqApp></faqApp>
</template>

<script>
import faqApp from '@/components/media/faqApp.vue';
export default {
  components: { faqApp },
  name: "Faq",
    mounted(){
         document.body.classList.add('faqApp', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('faqApp', 'otherPage');
    }
};
</script>