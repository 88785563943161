<template>
  <div class="all_products" :style="`background-image: url('${get_setting.body_image}')`">
  <div class="container">
    <div class="title_page">
      <h1>{{ translate_str("products") }}</h1>
    </div>
    <div class="slider_category">
      <div class="uk-position-relative uk-visible-toggle uk-light slider_catgg" tabindex="-1" uk-slider>

    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@l uk-grid">
        <li v-for="(cate,index) in get_all_category" :key="cate.id">
            <div class="uk-panel" @click="change_cate(cate.id)">
              <div :class="[`cate_box category_${cate.id}`,(index==0)?'active':'']">
                <div class="img_cate">
                  <img :src="cate.image" :alt="cate.name">
                </div>
                <div class="name_cate">
                  {{cate.name}}
                </div>
              </div>
            </div>
        </li>

        
    </ul>

    <a class="uk-position-center-left uk-position-small " href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small " href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6" v-for="prod in get_prod_by_category" :key="prod.id">
          <vs-card type="4">
    <template #title>
      <h3>{{prod.title}}</h3>
    </template>
    <template #img>
      <img :src="prod.image" alt="">
    </template>
    <template #text>
      <p></p>
    </template>
    <template #interactions>
      <vs-button class="btn-chat" shadow primary>
        <!-- <i class='bx bx-chat' ></i> -->
        <span class="span">
          20 شيكل
        </span>
      </vs-button>
    </template>
  </vs-card>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from 'jquery';
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "AllProductApp",
  data(){
    return{
       percent:0,
      color:'#ed1e24',
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
               get_setting:'get_setting',
               get_all_category:'get_all_category',
               get_prod_by_category:'get_prod_by_category',
               get_language_list:'get_language_list'
           })
        },
        methods:{
          ...mapActions({
            all_category_api:'all_category_api',
            all_prod_by_category_api:'all_prod_by_category_api'
          }),
          change_cate(id){
            $('.cate_box').removeClass('active');
            $(`.category_${id}`).addClass('active');
            console.log(id);
             this.all_prod_by_category_api(id);
          },
            translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
        },
        mounted(){
             const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          if(Object.keys(this.$route.params).length != 0){
           let cat_id = this.$route.params.id;
            this.all_category_api().then(()=>{
            $('.cate_box').removeClass('active');
            $(`.category_${cat_id}`).addClass('active');
             const uk = this.uk;
                uk.slider($('.slider_catgg'), {
                  index:cat_id
                });
            this.all_prod_by_category_api(cat_id);
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
          } else{
            this.all_category_api().then(()=>{
             const uk = this.uk;
                uk.slider($('.slider_catgg'), {
                  index:0
                });
            this.all_prod_by_category_api(this.get_all_category[0].id);
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
          }
        
        }
};
</script>

<style lang="scss">
.all_products{
    position: relative;
    z-index: 2;
    min-height: 100vh;
    padding-top: 140px;
    padding-bottom: 40px;
    background-image: url("~@/assets/body.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
    .slider_category{
      direction: ltr;
    }
    .vs-button__content {
    background-color: var(--active_color);
    color: white;
    font-size: 13px;
}
.vs-card-content.type-4 .vs-card{
  max-width: 100%;
}
    .vs-card-content.type-4 .vs-card__text{
              background: #fafafa;
    border-top: 1px solid rgb(244, 244, 244);
          h3{
            color: rgb(68, 68, 68);
          }
    }
    .vs-card__text{
      width: 100%;
      text-align: center;
          padding-top: 10px;
    padding-bottom: 10px;
    }
    .vs-card__img{
          padding: 40px;
    padding-bottom: 70px;
    }
    .vs-card__img img {
    // width: 100%;
    border-radius: 20px;
    display: block;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    object-fit: contain;
    width: 100%;
    height: 220px;
}
    .uk-slider-items{
          padding: 50px 0px 24px 0px;
    }
    .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    .row{
      padding-top: 50px;
    }
    .cate_box.active{
       background-color: var(--active_color);
       .name_cate{
          color: #fff;
        }
    }
    .cate_box{
      background-color: white;
      text-align: center;
      border-radius: 10px;
      box-shadow: 4px 4px 5px #c2c2c2;
       transition: 0.2s ease-in-out;
      &:hover{
        background-color: var(--active_color);
        .name_cate{
          color: #fff;
        }
      }
      cursor: pointer;
      &:hover{
        .img_cate{
         img{
         transform: scale(1.1);
         }
        }
      }
      .img_cate{
        overflow: hidden;
        border-radius: 10px;
      img{
        transition: 0.2s ease-in-out;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        height: 80px;
        width: 100%;
        object-fit: contain;
        padding: 8px;
        // border-radius: 100%;
      }
      }
      .name_cate{
        font-size: 14px;
        font-weight: 500;
        color: #000;
        padding-top: 8px;
        padding-bottom: 16px;
         transition: 0.2s ease-in-out;
      }
    }
}
@media(max-width:992px){
   .all_products{
       .vs-card__img img {
    width: 100%;
    height: 190px !important;
}
   }
}
</style>
