<template>
  <div class="faq_wraps" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
        <div class="title_page">
      <h1>{{ translate_str("Common questions") }}</h1>
    </div>
    <div class="faq_section">
   <div class="row">
    <div class="col-lg-12">
         <div class="faq_accord">
                <ul uk-accordion="multiple: false">
                     <template v-for="faq in get_faq_list">
                     <li :class="(faq.open == true?'uk-open':'')" :key="faq.id">
                        <a class="uk-accordion-title" href="#">
                          {{faq.question}}
                        </a>
                        <div class="uk-accordion-content">
                           <p v-html="faq.answer"></p>
                        </div>
                    </li>
                  </template>
                </ul>
    </div>
    </div>
   </div>
    </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "FaqApp",
   data(){
    return{
        percent:0,
      color:'#ed1e24',
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
               get_setting: "get_setting",
               get_faq_list:'get_faq_list',
               get_language_list:'get_language_list'
           })
        },
    methods:{
      ...mapActions({
        faqs_api:'faqs_api'
      }),
                 translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
    },
    mounted(){
       const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.faqs_api().then(()=>{
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
    }
};
</script>

<style lang="scss">
.faq_wraps{
   padding-top: 140px;
    padding-bottom: 80px;
       background-image: url('~@/assets/body.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    min-height: 100vh;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    /* Faq Section */
.faq_section{
    margin-top: 50px;
}
.img_faq{
    img{
        height: 350px;
        width: 100%;
        object-fit: cover;
    }
}
.faq_section .uk-accordion-title{
       color: black;
    background: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 17px 40px 17px 14px;
    border: 1px solid gainsboro;
}
.faq_section .uk-accordion-content{
    margin-top: 0px;
    padding: 30px;
    background-color: #fff;
    border-top: 1px solid gainsboro;
}
.faq_section .uk-accordion-content p{
    color: black;
    font-size: 14px;
    font-weight: 400;
        margin: 0;
}
.faq_section .uk-accordion-title::before {
    content: "";
    width: 1.2em;
    height: 1.2em;
    margin-left: 10px;
    float: left;
    background-image: url('~@/assets/arrow.svg');
        background-position: center center;
    background-size: contain;
    filter: invert(1);
}
.faq_section .faq_accord{
    margin-top: 0px;
}
.faq_section .more_faq{
    text-align: center;
    margin-top: 90px;
}
.faq_section .more_faq a{
font-weight: 700;
font-size: 26px;
color: #FFFFFF;
border: 1.5px solid #FF6433;
border-radius: 15px;
padding: 4px 43px;
transition: 0.3s ease-in-out;
}
.faq_section .more_faq a:hover{
    font-weight: 700;
    font-size: 26px;
    background-color: #FF6433;
    border: 1.5px solid #fff;
    }
}


@media(max-width:767px){
    .faq_section .uk-accordion-title{
    font-size: 14px !important;
    padding: 17px 20px 17px 14px !important;
}
.faq_section .uk-accordion-content p{
    font-size: 13px !important;
    font-weight: 400;
}
}
</style>
