import axios from "axios";
import text_en from "../json/text_en.json";
import text_ar from "../json/text_ar.json";

import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  namespaced: true,
  state: {
    my_string: {},
    active_lang: "",
    homepage_list: {},
    all_category: {},
    prod_by_category: {},
    setting: {},
    contact_res: {},
    promoters_list: {},
    delegates_list: {},
    company_history: {},
    all_news: {},
    news_info: {},
    add_rate_res: {},
    rating_list: {},
    faq_list: {},
    language_list: {},
    search_list: {},
  },
  getters: {
    get_mystring(state) {
      return state.my_string;
    },
    get_my_active_lang(state) {
      return state.active_lang;
    },
    get_homepage_list(state) {
      return state.homepage_list;
    },
    get_setting(state) {
      return state.setting;
    },
    get_all_category(state) {
      return state.all_category;
    },
    get_prod_by_category(state) {
      return state.prod_by_category;
    },
    get_contact_res(state) {
      return state.contact_res;
    },
    get_promoters_list(state) {
      return state.promoters_list;
    },
    get_delegates_list(state) {
      return state.delegates_list;
    },
    get_company_history(state) {
      return state.company_history;
    },
    get_all_news(state) {
      return state.all_news;
    },
    get_add_rate_res(state) {
      return state.add_rate_res;
    },
    get_rating_list(state) {
      return state.rating_list;
    },
    get_faq_list(state) {
      return state.faq_list;
    },
    get_news_info(state) {
      return state.news_info;
    },
    get_language_list(state) {
      return state.language_list;
    },
    get_search_list(state) {
      return state.search_list;
    },
  },
  mutations: {
    set_mystring(state, my_string) {
      state.my_string = my_string;
    },
    set_my_active_lang(state, myLang) {
      state.active_lang = myLang;
    },
    set_homepage_list(state, homepage_list) {
      state.homepage_list = homepage_list;
    },
    set_setting(state, setting) {
      state.setting = setting;
    },
    set_all_category(state, all_category) {
      state.all_category = all_category;
    },
    set_prod_by_category(state, prod_by_category) {
      state.prod_by_category = prod_by_category;
    },
    set_contact_res(state, contact_res) {
      state.contact_res = contact_res;
    },
    set_promoters_list(state, promoters_list) {
      state.promoters_list = promoters_list;
    },
    set_delegates_list(state, delegates_list) {
      state.delegates_list = delegates_list;
    },
    set_company_history(state, company_history) {
      state.company_history = company_history;
    },
    set_all_news(state, all_news) {
      state.all_news = all_news;
    },
    set_add_rate_res(state, add_rate_res) {
      state.add_rate_res = add_rate_res;
    },
    set_rating_list(state, rating_list) {
      state.rating_list = rating_list;
    },
    set_faq_list(state, faq_list) {
      state.faq_list = faq_list;
    },
    set_news_info(state, news_info) {
      state.news_info = news_info;
    },
    set_language_list(state, language_list) {
      state.language_list = language_list;
    },
    set_search_list(state, search_list) {
      state.search_list = search_list;
    },
  },
  actions: {
    fill_text_json({ commit }) {
      if (localStorage.getItem("Fav_lang") == null) {
        localStorage.setItem("Fav_lang", "ar");
        commit("set_my_active_lang", "ar");
        commit("set_mystring", text_ar);
      } else if (localStorage.getItem("Fav_lang") == "en") {
        commit("set_my_active_lang", "en");
        localStorage.setItem("Fav_lang", "en");
        commit("set_mystring", text_en);
      } else {
        commit("set_my_active_lang", "ar");
        localStorage.setItem("Fav_lang", "ar");
        commit("set_mystring", text_ar);
      }
    },
    async homepage_api({ commit }) {
      await axios({
        method: "get",
        url: `/home?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_homepage_list", res.data.data);
      });
    },
    async setting_api({ commit }) {
      await axios({
        method: "get",
        url: `/settings?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_setting", res.data.data);
      });
    },
    async all_category_api({ commit }) {
      await axios({
        method: "get",
        url: `/categories?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_all_category", res.data.data);
      });
    },
    async all_prod_by_category_api({ commit }, id) {
      await axios({
        method: "get",
        url: `/categories/${id}?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_prod_by_category", res.data.data);
      });
    },
    // 'first_name':form.first_name,
    // 'last_name':form.last_name,
    // 'email':form.email,
    // 'phone':form.phone,
    // 'cause':form.cause,
    // 'notes':form.notes,
    async contact_form_api({ commit }, form) {
      let fd = new FormData();

      fd.append("first_name", form.first_name);
      fd.append("last_name", form.last_name);
      fd.append("phone", form.phone);
      fd.append("cause", form.cause);
      fd.append("email", form.email);
      fd.append("note", form.notes);
      fd.append("files", form.files);
      // axios.post('/contact' ,fd).then((res)=>{
      // })
      await axios({
        method: "post",
        url: `/contact`,
        data: fd,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_contact_res", res.data);
      });
    },
    async all_promoters_api({ commit }) {
      await axios({
        method: "get",
        url: `/promoters?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_promoters_list", res.data.data);
      });
    },
    async all_delegates_api({ commit }) {
      await axios({
        method: "get",
        url: `/delegates?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_delegates_list", res.data.data);
      });
    },
    async company_history_api({ commit }) {
      await axios({
        method: "get",
        url: `/company_history?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_company_history", res.data.data);
      });
    },
    async all_news_api({ commit }) {
      await axios({
        method: "get",
        url: `/news?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_all_news", res.data.data);
      });
    },
    async new_details_api({ commit }, id) {
      await axios({
        method: "get",
        url: `/new-details/${id}?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_news_info", res.data.data);
      });
    },
    async add_rate_api({ commit }, rate_form) {
      await axios({
        method: "post",
        url: `/rate`,
        data: {
          first_name: rate_form.first_name,
          last_name: rate_form.last_name,
          rate: rate_form.rate,
          content: rate_form.content,
        },
        validateStatus: () => true,
      }).then((res) => {
        commit("set_add_rate_res", res.data);
      });
    },
    async rating_api({ commit }) {
      await axios({
        method: "get",
        url: `/ratings?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_rating_list", res.data.data);
      });
    },
    async faqs_api({ commit }) {
      await axios({
        method: "get",
        url: `/faqs?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_faq_list", res.data.data);
      });
    },
    async language_api({ commit }) {
      await axios({
        method: "get",
        url: `/Languages?lang=${localStorage.getItem("Fav_lang")}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_language_list", res.data);
      });
    },
    async search_api({ commit }, search_word) {
      await axios({
        method: "get",
        url: `/search?word=${search_word}`,
        validateStatus: () => true,
      }).then((res) => {
        commit("set_search_list", res.data.data);
      });
    },
  },
  modules: {},
});
