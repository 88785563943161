<template>
  <div class="history_wraps" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
        <div class="title_page">
      <h1> {{ translate_str("Company history") }}</h1>
    </div>
    <!-- p-lg-0 -->
   <!-- history sec -->
 <div class="section_2">
        <div class="container machineBoxes">
            <div class="row">
              <template v-for="histroy in get_company_history">
                <template v-if="histroy.position_image=='right'">
                   <div class="col-12" :key="histroy.id">
                    <div class="row">
                       <div class="col-lg-6 p-lg-0">
                           <img class="w-100" :src="histroy.image" :alt="histroy.title">
                        </div>
                        <div class="col-lg-6 text_col">
                            <div class="titlle_wrap2">
                                <h3>{{histroy.title}}</h3>
                            </div>
                           <p v-html="histroy.desc"></p>
                        </div>
                    </div>
                </div>
                </template>
                <template v-else>
                  <div class="col-12" :key="histroy.id">
                    <div class="row">
                        <div class="col-lg-6 text_col">
                            <div class="titlle_wrap2">
                                <h3>{{histroy.title}}</h3>
                            </div>
                           <p v-html="histroy.desc"></p>
                        </div>
                        <div class="col-lg-6 p-lg-0">
                             <img class="w-100" :src="histroy.image" :alt="histroy.title">
                        </div>
                    </div>
                </div>
                </template>
              </template>
            </div>
        </div>
    </div>
   <!-- end -->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "HistoryApp",
  data(){
    return{
        percent:0,
      color:'#ed1e24',
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
               get_setting: "get_setting",
               get_company_history:'get_company_history',
               get_language_list:'get_language_list'
           })
        },
    methods:{
      ...mapActions({
        company_history_api:'company_history_api'
      }),
          translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
    },
    mounted(){
             const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.company_history_api().then(()=>{
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
    }
};
</script>

<style lang="scss">
.history_wraps{
   padding-top: 140px;
    padding-bottom: 80px;
       background-image: url('~@/assets/body.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    min-height: 100vh;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    .rowup{
        padding-top: 70px;
    }

    

.machineBoxes{
  margin-top: 50px;
}

 .titlle_wrap2 h3 {
  color: var(--active_color);
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}
 .titlle_wrap2 p {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  position: relative;
}
.section_2 .text_col
{
  background-color: #fafafb;
     padding: 30px 30px 10px 30px;
     min-height: 400px;
}
.section_2 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.section_2 p{
  color: black;
  font-size: 17px;
}

/*end */
   
}
@media(max-width:992px){
    .history_wraps .row .col-12{
  margin-bottom: 35px;
}
.history_wraps .row .col-12:nth-child(odd) .row{
  flex-direction: column-reverse;
}
 .history_wraps h3 {
  font-size: 1.7rem !important;
}
.history_wraps p{
  font-size: 14px !important;
}
}
</style>
