<template>
  <div class="employee_info" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
        <div class="title_page">
      <h1>{{ translate_str("Employees") }}</h1>
    </div>

    <div class="tabs_emp">
      <div class="tab_e tab2 active" @click="change_to_dele()">
            <span>{{translate_str("Delegates")}}</span>
        </div>
        <div class="tab_e tab1" @click="change_to_promo()">
            <span>{{translate_str("Promoters")}}</span>
        </div>
    </div>
        <div class="row" v-show="promo_active">
        <div class="col-xl-3 col-lg-4 col-md-6 col-12" v-for="promo in get_promoters_list" :key="promo.id">
            <div class="emp_box">
                <div class="emp_img">
                   <template v-if="promo.image">
                     <img :src="promo.image" :alt="promo.name">
                   </template>
                   <template v-else>
                     <img class="logo_default" :src="get_setting.logo" :alt="promo.name">
                   </template>
                </div>
                <div class="emp_desc">
                    <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-user'></i></div>
                        <div class="name-ico">{{promo.name}}</div>
                    </div>
                     <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-map' ></i></div>
                        <div class="name-ico">{{promo.address}} </div>
                    </div>
                     <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-phone'></i></div>
                        <div class="name-ico">{{promo.phone}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div class="row" v-show="dele_active">
        <div class="col-xl-3 col-lg-4 col-md-6 col-12" v-for="dele in get_delegates_list" :key="dele.id">
            <div class="emp_box">
                <div class="emp_img">
                   <template v-if="dele.image">
                     <img :src="dele.image" :alt="dele.name">
                   </template>
                   <template v-else>
                     <img class="logo_default" :src="get_setting.logo" :alt="dele.name">
                   </template>
                </div>
                <div class="emp_desc">
                    <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-user'></i></div>
                        <div class="name-ico">{{dele.name}}</div>
                    </div>
                     <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-map' ></i></div>
                        <div class="name-ico">{{dele.address}} </div>
                    </div>
                     <div class="emp_data">
                        <div class="emp_ico"><i class='bx bx-phone'></i></div>
                        <div class="name-ico">{{dele.phone}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import $ from 'jquery';
export default {
  name: "EmplyeesInfoApp",
  data(){
    return{
        promo_active:false,
        dele_active:true,
        percent:0,
       color_load:'#ed1e24',
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
                get_setting:'get_setting',
                get_promoters_list:'get_promoters_list',
                get_delegates_list:'get_delegates_list',
                get_language_list:'get_language_list'
           })
        },
  methods:{
    ...mapActions({
        all_delegates_api:'all_delegates_api',
        all_promoters_api:'all_promoters_api'
    }),
    changeTab(){
        let link_tab = document.querySelectorAll('.tab_e');
        link_tab.forEach((elem)=>{
            elem.addEventListener('click',()=>{
                link_tab.forEach((elem)=>{
                    elem.classList.remove('active');
                })
                elem.classList.add('active');
            })
        })
    },
    hoverBox(){
        let allbox = document.querySelectorAll('.emp_box');
        allbox.forEach((elem)=>{
            elem.addEventListener('mouseenter',()=>{
                elem.classList.add('hover_box');
                elem.querySelectorAll('i').forEach((ico)=>{
                    ico.classList.add('bx-flashing');
                })
                
            })
            elem.addEventListener('mouseleave',()=>{
                elem.classList.remove('hover_box');
                elem.querySelectorAll('i').forEach((ico)=>{
                    ico.classList.remove('bx-flashing');
                })
            })
        })
    },
    change_to_promo(){
         $('.tab1').removeClass('active');
          $('.tab2').addClass('active');
        this.promo_active = true;
        this.dele_active = false;
    },
       change_to_dele(){
         $('.tab2').removeClass('active');
          $('.tab1').addClass('active');
        this.promo_active = false;
        this.dele_active = true;
    },
    translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },

  },
  mounted(){
    this.changeTab();
         const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color_load,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.all_promoters_api().then(()=>{
          this.all_delegates_api().then(()=>{
             this.hoverBox();
            loading.close();
            clearInterval(interval)
            this.percent = 0
          })
        });
    
  }
};
</script>

<style lang="scss">
.employee_info{
   padding-top: 140px;
    padding-bottom: 80px;
       background-image: url('~@/assets/body.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    min-height: 100vh;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    .row{
        padding-top: 50px;
    }
    .tabs_emp{
        background-color: #fff;
        border: 1px solid #dbdbdb;
        display: flex;
        border-radius: 30px;
        width: 300px;
        margin: 0 auto;
        margin-top: 30px;
        .tab_e{
            flex: 0 0 50%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
             border-radius: 30px;
             font-size: 15px;
             font-weight: 500;
             cursor: pointer;
             transition: 0.3s ease-in-out;
        }
        .tab_e.active{
            background-color: var(--active_color);
            color: #fff;
        }
    }
    .hover_box{
        i{
            color: var(--active_color) !important;
        }
    }
    .emp_box{
        background-color: #EEEEEE;
        padding: 30px 10px 10px 10px;
        box-shadow: 2px 4px 7px gainsboro;
       border-radius: 30px;
         position: relative;
         transition: 0.2s ease-in-out;
         border: 1px solid #e1e1e1;
         margin-bottom: 50px;
         &:hover{
            background-color: rgb(229, 229, 229);
             .emp_img{
            position: relative;
            text-align: center;
            img{
              transform: scale(1.1);
            }
             }
            // &::before{
            // content: '';
            // background-color: gainsboro;
            // }
         }
         &::before{
            content: '';
            transition: 0.2s ease-in-out;
            border-top-right-radius: 30px;
         border-top-left-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: white;
         }
         .emp_desc{
            padding:18px 10px 0px 10px;
         }
        .emp_img{
            position: relative;
            text-align: center;
            img{
                border: 5px solid gainsboro;
                border-radius: 100%;
                padding: 5px;
                background-color: #fff;
                object-fit: cover;
                width: 132px;
                height: 132px;
                object-position: center;
                transition: 0.2s linear;
            }
            img.logo_default{
                object-fit: contain !important;
            }
        }
        .emp_data{
            display: flex;
            align-content: center;
            margin-bottom: 14px;
            .emp_ico{
               i{
                 font-size: 24px;
                vertical-align: middle;
                margin-top: -10px;
                padding-left: 8px;
                font-weight: 400;
                // color: var(--active_color);
                color: #6f6f6f;
               }
            }
            .name-ico{
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

@media(max-width:767px){
    .employee_info .emp_box{
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
