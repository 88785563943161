<template>
  <feedbackApp></feedbackApp>
</template>

<script>
import feedbackApp from '@/components/media/feedbackApp.vue';
export default {
  components: { feedbackApp },
  name: "FeedBack",
    mounted(){
         document.body.classList.add('feedbackApp', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('feedbackApp', 'otherPage');
    }
};
</script>