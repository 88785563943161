<template>
  <div class="feedback_wraps" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
        <div class="title_page">
      <h1>{{ translate_str("Feedback") }}</h1>
    </div>
     <div class="row row_up">
            <div class="col-lg-7">
              <form @submit.prevent="submit_rate()" class="form_emp">
                <div class="title_form">
                  <h1>{{get_mystring.feedti2}}</h1>
                </div>
                 <div class="row">
                <div class="col-lg-6 mb-4">
                   <vs-input v-model="fname" required  :placeholder="translate_str('first name')">
                      <template #icon>
                        <i class='bx bx-user'></i>
                      </template>
                    </vs-input>
                </div>
                 <div class="col-lg-6 mb-4">
                   <vs-input v-model="lname" required  :placeholder="translate_str('last name')">
                      <template #icon>
                        <i class='bx bx-user'></i>
                      </template>
                    </vs-input>
                </div>
                <div class="col-12  mb-4">
                     <b-rate 
                    size="is-medium"
                    v-model="rate"
                    icon-pack="fas" 
                    required
                    ></b-rate>
                </div>
                 <div class="col-lg-12 mb-4">
                   <b-field >
                      <b-input v-model="content" required :placeholder="translate_str('feeedbackTextArea')" maxlength="200" type="textarea"></b-input>
                </b-field>
                </div>

                <div class="col-12">
                   <b-button native-type="submit" type="is-success">
                    {{translate_str('Add Now')}}
                   </b-button>
                </div>
              </div>
              </form>
            </div>
            <div class="col-lg-5 orderpx">
                <div class="img_feedback">
                     <img class="pin" src="~@/assets/pin.png" alt="">
                    <img :src="get_setting.about_image" alt="">
                </div>

            </div>
     </div>
    <div class="user_say">
         <div class="slider_say">
                <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
      
                    <ul class="uk-slider-items uk-child-width-1 uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-3@l uk-grid">
                        <li v-for="user in get_rating_list" :key="user.id">
                            <div class="uk-panel">
                            <div class="box_user_say">
                                <img class="bitmap" src="~@/assets/Bitmap.svg" alt="bitmap">
                                <div class="text_say">
                                    <p v-html="user.content"></p>
                                </div>
                                <div class="user_rate_name">
                                    <div class="ratex">
                                        <b-rate 
                                        v-model="user.rate"
                                        icon-pack="fas" 
                                        ></b-rate>
                                    </div>
                                    <div class="username_say">
                                        <p>{{user.first_name}} {{user.last_name}}</p>
                                    </div>
                                </div>
                                <div class="img_profile">
                                    <img src="~@/assets/emp.png" alt="username">
                                </div>
                            </div>
                            </div>
                        </li>
                     
                   
                    </ul>
                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>
            </div>
    </div>
   
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: "feedbackApp",
  data(){
    return{
        fname:'',
        lname:'',
        rate:5,
        content:'',
         percent:0,
      color:'#ed1e24',
    }
  },
   computed:{
           ...mapGetters({
               get_mystring:'get_mystring',
                get_setting: "get_setting",
                get_add_rate_res:'get_add_rate_res',
                get_rating_list:'get_rating_list',
                get_language_list:'get_language_list'
           })
        },
    methods:{
       ...mapActions({
         add_rate_api:'add_rate_api',
         rating_api:'rating_api',
       }),
       submit_rate(){
        let my_rate = { 
            'first_name':this.fname,
              'last_name':this.lname,
              'rate':this.rate,
              'content':this.content
        }
        this.add_rate_api(my_rate).then(()=>{
            console.log(this.get_add_rate_res);
            if(this.get_add_rate_res.status == true){
           this.openNotification('bottom-right', 'success' , 'تم الإرسال' , 'تم ارسال البيانات بنجاح شكرا لك .');
           this.fname = '';
           this.lname = '';
           this.content = '';
           this.rate  = 5;
        }else{
           this.openNotification('bottom-right', 'danger' , 'حدث خطأ' , 'حدث خطأ , يرجى المحاولة مرة اخرى .');
        }
        });
       },
                translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
               // Notifucation
      openNotification(position = null, color , title , text) {
      this.$vs.notification({
        color,
        position,
        title: title,
        text: text,
      });
    },
    },
    mounted(){
             const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          this.rating_api().then(()=>{
            loading.close();
            clearInterval(interval)
            this.percent = 0
        });
    }   
};
</script>

<style lang="scss">
.feedback_wraps{
   padding-top: 140px;
    padding-bottom: 80px;
       background-image: url('~@/assets/body.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    min-height: 100vh;
    &::before{
        content: '';
        // background: linear-gradient(45deg, black, #404040);
        background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.88;
    }
  .title_page{
      text-align: center;
       position: relative;
         z-index: 2;
      h1{
        color: var(--active_color);
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
     .form_emp{
    background-color: #FFFFFF;
    padding: 30px 14px 20px 14px;
    border-radius: 14px;
    }
    .title_form{
      h1{
        color: var(--active_color);
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 20px;
        padding-right: 10px;
      }
    }
    .row_up{
      padding-top: 50px;
      display: flex;
      align-items: center;
      .formpd{
        margin-bottom:30px;
      }
      textarea{
        height: 120px;
        width: 100%;
      }
        .img_feedback{
            width: 85%;
            margin: 0 auto;
            position: relative;
            padding-left: 20px;
            transform: rotate(-5deg);
             .pin{
 position: absolute;
    width: 35px;
    height: 35px;
     right: -4px;
    top: -20px;
    z-index: 3;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    }
            &::before{
                content: '';
                background-image: url('~@/assets/bord1.png');
                background-position: right;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 80%;
                top: 10%;
                left: 0;
                transform: scaleX(-1);
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                position: relative;
            }
        }

    }

    .slider_say{
    direction: ltr;
    text-align: left;
    margin-top: 130px;
}
.user_say .title_user{
    text-align: center;
    color: #FFFFFF;
}
.user_say .title_user h1{
font-weight: 700;
font-size: 36px;
color: #FFFFFF;
}
.user_say .title_user h2{
    font-weight: 400;
    font-size: 38px;
    color: #FFFFFF;
    }
    .user_say .title_user p{
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        }
    .box_user_say{
        background: #fff;
        box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
        border-radius: 100px 24px 24px 24px;
        padding: 65px 20px 20px 20px;
        width: 100%;
        direction: rtl;
    text-align: right;
    position: relative;
    margin: 0 auto;
    }
    .box_user_say .text_say p{
    font-weight: 500;
    font-size: 15px;
    color: black;
       overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 68px;
    }
    .box_user_say .username_say p {
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        padding-left: 15px;
        margin: 0;
    }
    .box_user_say .user_rate_name{
        margin-top: 40px;
        text-align: left;
        direction: rtl;
    }
    .box_user_say .ratex .star_r  i{
       color: #DCDCDC;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        width: 18px;
        height: 17px;
        font-weight: 600;
    }
    .box_user_say .ratex .star_r.fill i{
        color: #FFA500;
    }
    .user_say .uk-slider-items.uk-grid {
        flex-wrap: nowrap;
        padding-bottom: 60px;
    }
    .user_say .img_profile{
        position: absolute;
       top: 80%;
    right: 10%;
    }
    .user_say .img_profile img{
    box-shadow: 0px 16px 24px rgb(0 24 52 / 8%);
    border-radius: 100%;
    width: 90px;
    height: 90px;
    padding: 5px;
    border: 5px solid gainsboro;
    background-color: white;
    object-fit: cover;
    }
    .user_say .bitmap{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 20px;
        top: 20px;
    }

}

 @media(max-width:992px){
    .feedback_wraps{
          .row_up{
            .orderpx{
                order: -1;
                margin-top: 40px;
                margin-bottom: 50px;
            }
    }
    }
    .feedback_wraps .slider_say {
    direction: ltr;
    text-align: left;
    margin-top: 60px;
}
 }
</style>
