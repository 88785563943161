<template>
  <div id="app">
   <headerApp></headerApp>
    <router-view :key="$route.fullPath"></router-view>
    <footerApp></footerApp>
  </div>
</template>

<script>
import headerApp from "@/components/global/header.vue";
import footerApp from "@/components/global/footer.vue";
export default {
  name: "App",
  components: {
    headerApp,
    footerApp
  },
};
</script>

<style lang="scss"></style>
