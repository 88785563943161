import Vue from "vue";
import App from "./App.vue";
import store from './store/store.js';
import "./registerServiceWorker";



// # import Style Lib # == Dev By Remak == 
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap-grid.min.css';
import uk from 'uikit'
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js'
Vue.mixin({
  data: function () {
    return {
      get uk () {
        return uk
      },
    }
  },
});
import Vuesax from 'vuesax'
import 'boxicons'
import 'vuesax/dist/vuesax.css' //Vuesax styles
  Vue.use(Vuesax, {
    // options here
    colors: {
      primary:'#660066',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      // dark:'rgb(36, 33, 69)'
      dark:'#1e2023'
    },
  });
  import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy);

  import './scss/main.scss';


  // end Styles


// import google map api
// google map
import * as VueGoogleMaps from 'vue2-google-maps';
 import Geocoder from "@pderas/vue2-geocoder";
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAoOQA_ac1dbUgyXKxal0q3L9M4CtKjq5s',
    libraries: 'places',
  }
});
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage:    'ar', // e.g. 'en'
  defaultMode:       'lat-lng', // or 'lat-lng'
  googleMapsApiKey:   'AIzaSyAoOQA_ac1dbUgyXKxal0q3L9M4CtKjq5s'
});



// Import Views Dev.Omar Eyad --Remak
import Home from '@/views/Home.vue';
import AllProduct from '@/views/AllProduct.vue';
import Employees from '@/views/Employees.vue';
import EmployeesInfo from '@/views/EmployeesInfo.vue';
import Faq from '@/views/Faq.vue';
import Feedback from '@/views/Feedback.vue';
import AllNews from '@/views/AllNews.vue';
import news_details from '@/components/media/news_info.vue'
import History from '@/views/History.vue';

// import Routes
import VueRouter from 'vue-router';
import Router from 'vue-router';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    document.querySelector('body').scrollIntoView({ behavior: 'instant' });
},
routes: [ 
  { path: '/', component: Home },
  { path: '/home', component: Home, name: "Home" },
  { path: '/products', component: AllProduct, name: "allproducts" },
  { path: '/products/:id?', component: AllProduct, name: "allproducts" },
  { path: '/media-center', component: Employees, name: "Employees" },
  { path: '/employees', component: EmployeesInfo, name: "EmployeesInfo" },
  { path: '/faq', component: Faq, name: "Faq" },
  { path: '/feedback', component: Feedback, name: "Feedback" },
  { path: '/news', component: AllNews, name: "AllNews" },
  {path: '/news/:id', component: news_details, name: 'newsdetails'},
  { path: '/company-history', component: History, name: "History" },

  
  
  
]
});

// aos animation 
import AOS from 'aos'
import 'aos/dist/aos.css'
//  end aos

// typing Text
import { VueTypedJs } from 'vue-typed-js'
Vue.use(VueTypedJs)
// end text

// phone tel
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput, {
  mode: 'international',
  showSearchBox:true,
  showFlags:false
});
// end tel


Vue.config.ignoredElements = [/^ion-/]
Vue.config.productionTip = false;



// Axios BaseURL ======= Vue JS - Remak #
import axios from 'axios';
 axios.defaults.baseURL = "https://www.shaddad.ps/admin/api";
//axios.defaults.baseURL = "https://shaddad.khotwa-tech.com/api"


store.dispatch('setting_api').then(()=>{
  const my_setting = store.getters['get_setting'];
  const favicon = document.querySelectorAll("link[rel~='icon']");
  const title = document.querySelector('.title_site');
  const desc = document.querySelector('.desc_site');
  title.innerHTML = my_setting.seo.title;
   desc.innerHTML = my_setting.seo.desc;
  favicon.forEach((elem)=>{
    elem.href = my_setting.favicon;
  })
  store.dispatch('language_api').then(()=>{
    store.dispatch('fill_text_json').then(()=>{
      new Vue({
        router,
        store,
        render: (h) => h(App),
        mounted() {
          AOS.init({
            // Global settings:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 500, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: true, // whether animation should happen only once - while scrolling down
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
          })
        },
      }).$mount("#app");
      });
  })
});