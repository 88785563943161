<template>
  <employeeApp></employeeApp>
</template>

<script>
import employeeApp from '@/components/media/employeeApp.vue';
export default {
  components: { employeeApp },
  name: "MediaCenter",
    mounted(){
         document.body.classList.add('MediaCenter', 'otherPage');
    },
    destroyed(){
      document.body.classList.remove('MediaCenter', 'otherPage');
    }
};
</script>