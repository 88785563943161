<template>
  <!-- Media Center Form -->
  <div class="media_center" :style="`background-image: url('${get_setting.body_image}')`">
    <div class="container">
      <div class="title_page">
        <h1>{{ translate_str("media center") }}</h1>
      </div>
      <div class="row row_up">
        <div class="col-lg-7">
          <form @submit.prevent="submit_contact()" class="form_emp">
            <div class="title_form">
              <h1>{{ translate_str("Connect Us") }}</h1>
            </div>
            <div class="row">
              <div class="col-lg-6 formpd">
                <vs-input v-model="fname" required :placeholder="translate_str('first name')">
                  <template #icon>
                    <i class="bx bx-user"></i>
                  </template>
                </vs-input>
              </div>
              <div class="col-lg-6 formpd">
                <vs-input v-model="lname" required :placeholder="translate_str('last name')">
                  <template #icon>
                    <i class="bx bx-user"></i>
                  </template>
                </vs-input>
              </div>
              <div class="col-lg-12 formpd">
                <vs-input v-model="email" required :placeholder="translate_str('email')">
                  <template #icon>
                    <i class="bx bx-envelope"></i>
                  </template>
                </vs-input>
              </div>
              <div class="col-lg-12 formpd">
                <vue-tel-input
                  :placeholder="translate_str('Phone')"
                  :inputOptions="inp_opt"
                  v-model="mobile"
                  required
                  @validate="writeMobile($event)"
                  :dropdownOptions="opt"
                ></vue-tel-input>
                <template v-if="valid_mobile">
                  <span class="successMSG">
                    {{ get_mystring.valid1 }}
                  </span>
                </template>
                <template v-if="!valid_mobile && mobile !== ''">
                  <span class="errorMSG">{{ get_mystring.valid2 }}</span>
                </template>
              </div>
              <div class="col-lg-12 formpd">
                <vs-select
                  :color="color"
                  :placeholder="translate_str('reason for communication')"
                  @change="chnage_selc()"
                  v-model="valSelect"
                  required
                >
                  <vs-option :label="get_mystring.selopt1" :value="get_mystring.selopt1">
                    {{ get_mystring.selopt1 }}
                  </vs-option>
                  <vs-option :label="get_mystring.selopt2" :value="get_mystring.selopt2">
                    {{ get_mystring.selopt2 }}
                  </vs-option>
                  <vs-option :label="get_mystring.selopt3" :value="get_mystring.selopt3">
                    {{ get_mystring.selopt3 }}
                  </vs-option>
                  <vs-option :label="get_mystring.selopt4" :value="get_mystring.selopt4">
                    {{ get_mystring.selopt4 }}
                  </vs-option>
                </vs-select>
              </div>

              <div class="col-lg-12 formpd">
                <template v-if="valSelect == get_mystring.selopt1">
                  <section>
                    <b-field>
                      <!--  -->
                      <b-upload v-model="dropFiles" drag-drop  required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>{{ get_mystring.cv }}</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                    <div class="tags">
                      <span
                       v-if="dropFiles"
                        class="tag is-primary"
                      >
                        {{ dropFiles.name }}
                        <button
                          class="delete is-small"
                          type="button"
                          @click="deleteDropFile()"
                        ></button>
                      </span>
                    </div>
                  </section>
                </template>
                <template v-if="valSelect == get_mystring.selopt2 || valSelect == get_mystring.selopt3 || valSelect == get_mystring.selopt4">
                  <b-field>
                    <b-input
                      required
                      v-model="notes"
                      :placeholder="get_mystring.area1"
                      maxlength="200"
                      type="textarea"
                    ></b-input>
                  </b-field>
                </template>
              </div>
              <div class="col-12">
                <!--  :disabled="!valid_mobile || mobile == '' || fname == '' || lname == '' || email=='' || valSelect==''" -->
                <b-button native-type="submit" type="is-success" :loading="loadform"  :disabled="!valid_mobile || mobile == '' || fname == '' || lname == '' || email==''">{{
                 translate_str('send now')
                }}</b-button>
                <!-- {{valid_mobile}} -->
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-5 infoPin">
          <div class="company_infos">
            <img class="pin" src="~@/assets/pin.png" alt="" />
            <div class="title_infos">
              <h1>{{translate_str('Contact Information')}}</h1>
              <div class="desc_infos">
                <span>{{get_setting.name}}</span>
                <span>{{get_setting.address}}</span>
              </div>
              <div class="contact_infos">
                <div class="single_info">
                  <span class="tit">
                    <span>{{translate_str('Phone')}} : {{ get_setting.phone }}</span></span
                  >
                  <!-- <span class="val">505718-042</span> -->
                </div>
                <div class="single_info">
                  <span class="tit">
                   <span>{{translate_str('fax')}} : {{ get_setting.fax }}</span></span
                  >
                  <!-- <span class="val">505717-042</span> -->
                </div>
                <div class="single_info">
                  <span class="tit">
                    <span>{{translate_str('email')}} : {{ get_setting.email }}</span></span
                  >
                  <!-- <span class="val">info@shaddad.ps</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EmplyeesApp",
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      valSelect: "",
      color: "success",
      dropFiles: null,
      loadform:false,
      notes:'',
      opt: {
        disabled: false,
        showDialCodeInList: true,
        showDialCodeInSelection: false,
        showFlags: true,
        showSearchBox: true,
        tabindex: 0,
        width: "390px",
      },
      inp_opt: {
        // placeholder: "رقم الموبايل"
      },
      valid_mobile: false,
      error_msg: "",
       percent:0,
       color_load:'#ed1e24',
      
    };
  },
  computed: {
    ...mapGetters({
      get_mystring: "get_mystring",
      get_setting: "get_setting",
      get_contact_res:'get_contact_res',
      get_language_list:'get_language_list'
    }),
  },
  methods: {
    ...mapActions({
      contact_form_api:'contact_form_api'
    }),
    writeMobile(event) {
      console.log(event);
      this.valid_mobile = event.valid;
    },
    chnage_selc() {
      console.log(this.valSelect);
    },
    deleteDropFile() {
      this.dropFiles=null;
    },
    submit_contact(){
      this.loadform = true;
      let my_form={
        'first_name':this.fname,
        'last_name':this.lname,
        'email':this.email,
        'phone':this.mobile.replace(/\s/g, ''),
        'cause':this.valSelect,
        'files':this.dropFiles,
        'notes':this.notes

      }
      this.contact_form_api(my_form).then(()=>{
        if(this.get_contact_res.status ==true){
           this.openNotification('bottom-right', 'success' , 'تم الإرسال' , 'تم ارسال البيانات بنجاح شكرا لك .');
           this.fname = '';
           this.lname = '';
           this.email= '';
           this.mobile = '';
           this.valSelect = '';
           this.dropFiles = null;
           this.notes = '';
          this.loadform = false;
        }else{
           this.openNotification('bottom-right', 'danger' , 'حدث خطأ' , 'حدث خطأ , يرجى المحاولة مرة اخرى .');
            this.loadform = false;
        }
      })
    },
          translate_str(str) {
      if (this.get_language_list[str] == null) {
        return str;
      }
      if (
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == null ||
        this.get_language_list[str][localStorage.getItem("Fav_lang")] == ""
      ) {
        return this.get_language_list[str]["default"];
      }else{
         return this.get_language_list[str][localStorage.getItem("Fav_lang")];
      }
       
    },
             // Notifucation
      openNotification(position = null, color , title , text) {
      this.$vs.notification({
        color,
        position,
        title: title,
        text: text,
      });
    },
  },
  mounted(){
       const loading = this.$vs.loading({
            percent: this.percent,
            color: this.color_load,
            type:'corners'
          });
          const interval = setInterval(() => {
            if (this.percent <= 100) {
              loading.changePercent(`${this.percent++}%`)
            }
          }, 40);
          setTimeout(()=>{
             loading.close();
            clearInterval(interval)
            this.percent = 0
          },500)
  }
};
</script>

<style lang="scss">
.media_center {
  padding-top: 140px;
  padding-bottom: 80px;
  background-image: url("~@/assets/body.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  min-height: 100vh;
  position: relative;
  &::before {
    content: "";
    // background: linear-gradient(45deg, black, #404040);
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.88;
  }
  .title_page {
    text-align: center;
    position: relative;
    z-index: 2;
    h1 {
      color: var(--active_color);
      font-weight: 600;
      font-size: 2.5rem;
    }
  }
  .uploadIco {
    margin: 0;
    i {
      font-size: 70px;
    }
  }
  .upload-draggable {
    p {
      margin: 0;
    }
  }
  .form_emp {
    background-color: #ffffff;
    padding: 30px 14px 20px 14px;
    border-radius: 14px;
  }
  .title_form {
    h1 {
      color: var(--active_color);
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 20px;
      padding-right: 10px;
    }
  }
  .row_up {
    padding-top: 50px;
    display: flex;
    align-items: center;
    .formpd {
      margin-bottom: 30px;
    }
    .errorMSG {
      font-size: 0.7rem;
      position: relative;
      padding: 0px 7px;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      overflow: hidden;
      color: rgb(242, 19, 93);
    }
    .successMSG {
      font-size: 0.7rem;
      position: relative;
      padding: 0px 7px;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      overflow: hidden;
      color: rgb(23, 201, 100);
    }

    .vti__dropdown-list {
      z-index: 550 !important;
      right: 0;
    }

    .vti__dropdown-item {
      text-align: right;
      unicode-bidi: plaintext;
    }
    .vti__input {
      border: none;
      border-radius: 0 2px 2px 0;
      width: 100%;
      outline: none;
      unicode-bidi: plaintext !important;
      padding-right: 10px !important;
      padding-left: unset !important;
      text-align: right;
      background-color: #f5f7f8;
      padding: 7px 13px !important;
      color: black;
      height: 39px;
      font-size: 0.84rem;
      color: black;
      opacity: 1;
      font-weight: 500;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .vue-tel-input:focus-within {
      box-shadow: none;
      border-color: #66afe9;
    }
    .vue-tel-input:first-child {
      border: 1px solid transparent !important;
      text-align: left;
    }

    .vti__dropdown {
      background-color: #e4e4e4 !important;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    // .vti__dropdown-item {
    //     unicode-bidi: isolate-override !important;
    // }
    .vti__search_box {
      width: 100% !important;
      background-color: white !important;
      color: black !important;
    }
    .vs-select__option {
      color: white !important;
    }
    .vs-select__option.activeOption {
      color: rgba(var(--vs-color), 1) !important;
    }
    .vs-select__input {
      text-align: right;
      padding-right: 13px;
    }
    .vs-select .vs-icon-arrow {
      left: 15px;
      right: unset;
      transform: rotate(135deg);
    }
    .vs-select.activeOptions .vs-icon-arrow {
      transform: rotate(-45deg);
    }
    .vs-select-content {
      width: 100%;
      max-width: 100%;
    }
    .vs-select__label {
      justify-content: flex-start;
      right: 14px;
      left: unset;
      opacity: 1;
    }
    .vs-select__label--hidden.vs-select__label--placeholder {
      margin-top: -5px !important;
    }
  }
  .company_infos {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    transform: rotate(-5deg);
    margin: 0 auto;
    .pin {
      position: absolute;
      width: 35px;
      right: -4px;
      top: -20px;
      filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg)
        saturate(600%) contrast(0.8);
    }

    .title_infos {
      position: relative;
      h1 {
        color: var(--active_color);
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
    .desc_infos {
      span {
        display: block;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .contact_infos {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
      .single_info {
        display: block;
        margin-bottom: 5px;
        .tit {
          padding-left: 4px;
        }
        span {
          color: black;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .infoPin {
    order: -1;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
</style>
